@import "../../global.scss";

.showParticipantsList{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    z-index: 9999;
    >div{
        width: 60vw;
        height: 90vh;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;  
        display: flex;
        flex-direction: column;
        position: relative;
        
        //header
        > div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            >:nth-child(1){
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
            > div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }
            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }

        // body table
        >div:nth-child(2){
            padding: 20px;
            overflow-y: auto;
            >table{
                width: 100%;
                border-collapse: collapse;
                >thead{
                    border-bottom: 0.3px solid rgba(0,0,0,0.1);
                    >tr{
                        text-align: left;
                        >th{
                            opacity: 0.9;
                            padding-right: 20px;
                        }
                    }
                }
                >tbody{
                    >tr{
                        >td{
                            opacity: 0.9;
                            margin-right: 20px;
                            // line-height: 30px;
                        }
                    }
                }
            }
        }
        //footer
        > div:nth-last-child(1) {
            width: 100%;
            height: 60px;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 20px;
            position: absolute;
            bottom: 0;
            box-sizing: border-box;
            // close button
            > div:nth-child(1) {
                font-size: 13px;
                font-weight: 500;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: white;
                color: black;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
            }
        }
    }
}