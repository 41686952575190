.container {

    .drum-confetti-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 0 0 0;
    
        .drum-confetti-container-child {
            display: flex;
            justify-content: center;
            padding: 0 0 0 0;
            .confetti-container {
    
            }
            .drum-roll-container {
                display: flex;
                justify-content: center;
                .drum-roll-gif{
                    height: 490px;
                }
            }
        }
    }
}




@media screen and (max-width:720px) {

    .container {
        .drum-confetti-container {

            .drum-confetti-container-child {
    
                .confetti-container {
    
                }
                .drum-roll-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .drum-roll-gif{
                        height: 370px;
                        margin-top: 35px;
                    }
                }
            }
        }
    }
   
}