@import "../../global.scss";

.draw_raffle_view_model {
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    //main view
    > :nth-child(1) {
        width: 100%;
        max-height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        //background ticket
        > :nth-child(1) {
            position: absolute;
        }

        //ticket content
        >.ticket_content {
            width: 97%;
            max-height: 100%;
            position: relative;
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: rgba(0, 0, 0, 1);
            box-sizing: border-box;
            margin-bottom: 20px;

            //title
            >div:nth-child(1) {
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }

            //sub-title
            >div:nth-child(2) {
                font-size: 16px;
                margin-left: auto;
                margin-right: auto;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0.7;
                margin-top: 2px;
            }

            //ticket body
            > :nth-child(3) {
                display: flex;
                flex-direction: column;
                align-items: center;
                // padding: 10px;
                justify-content: center;
                width: 100%;
                // flex: 1;

                //raffle feilds view 1st row
                > :nth-child(1) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // background-color: rgba(245, 245, 245, 0.4);
                    justify-content: center;
                    margin-top: 5px;
                    // margin-left: 60px;
                    gap: 10px;
                    width: 100%;

                    // details-box
                    >.details-box {
                        height: 160px;
                        max-height: 160px;
                        width: 100%;
                        flex: 1;
                        background-color: rgba(245, 245, 245, 0.4);
                        padding: 10px 10px;
                        border-radius: 10px;
                        overflow-y: scroll;
                        display: flex;
                        justify-content: center;

                        > :nth-child(1) {
                            position: fixed;
                            justify-content: center;
                            font-weight: bold;
                            padding-bottom: 5px;
                        }

                        >:nth-child(2) {
                            margin-top: 20px;
                            width: 100%;

                            >.detail {
                                padding-bottom: 10px;
                                display: flex;
                                font-size: 14px;
                                gap: 10px;

                                // font-weight: bold;
                                >.lable {
                                    font-weight: bold;
                                    // width: 40%;
                                    // padding-right: 8px;
                                }

                                >.value {
                                    // width: 60%;
                                    display: flex;
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }

                    // Logo
                    >.logo {
                        // background-color: rgba(245, 245, 245, 0.4);
                        background-color: rgba(245, 245, 245, 0.4);
                        padding: 10px 10px;
                        border-radius: 10px;
                        height: 160px;
                        display: flex;
                        align-items: center;
                    }

                    // prizes-table
                    >.prizes-table {
                        height: 160px;
                        width: 100%;
                        flex: 1;
                        background-color: rgba(245, 245, 245, 0.4);
                        padding: 10px 10px;
                        border-radius: 10px;
                        overflow: auto;
                        scrollbar-width: thin;
                        scrollbar-color: white;
                        display: flex;
                        justify-content: center;

                        > :nth-child(1) {
                            position: fixed;
                            justify-content: center;
                            font-weight: bold;
                            padding-bottom: 5px;
                        }

                        >:nth-child(2) {
                            margin-top: 20px;
                            width: 100%;

                            >table {
                                width: 100%;

                                thead th {
                                    width: 100%;
                                    top: 0;
                                    font-size: 14.5px;
                                }

                                tbody {
                                    width: 100%;
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    .prizes-table::-webkit-scrollbar {
                        // -webkit-appearance: none;
                        width: 25px;
                    }

                    .prizes-table::-webkit-scrollbar-track {
                        background-color: aqua;
                    }
                    .prizes-table::-webkit-scrollbar-thumb {
                        // border-radius: 10px;
                        // background-color: #bbb;
                        // border: 10px solid black;
                        // -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
                        background: linear-gradient(#642bff, #ff22e6);
                    }
                }

                //raffle feilds view 1st row
                >.long_description {
                    padding: 10px 10px;
                    background-color: rgba(245, 245, 245, 0.4);
                    // flex: 1;
                    margin-top: 10px;
                    font-size: 14px;
                    border-radius: 6px;
                    height: 80px;
                    width: 96.5%;
                    overflow-y: scroll;
                }
            }
        }
    }
}

// @media screen and (max-width:720px) {
//     .draw_raffle_view_model {

//         // main view
//         >:nth-child(1) {
//             transform: rotateZ(90deg);
//             min-width: 100vh;
//             min-height: 100vw;
//             // overflow: scroll;

//             //background image
//             >:nth-child(1) {
//                 width: 100vh;
//                 height: 100%;
//             }

//             //content
//             >:nth-child(2) {
//                 max-width: 100vh;
//                 max-height: 490px;
//                 padding: 0px 0px;

//                 // title
//                 >div :nth-child(1) {
//                     z-index: 9999;
//                 }

//                 // body
//                 >:nth-child(3) {
//                     max-width: 100vh;

//                     // col2
//                     >:nth-child(2) {
//                         // img or video
//                         min-width: 100px;
//                         max-height: 100px;
//                         // overflow: hidden;

//                         >:nth-child(1) {
//                             max-width: 100px;
//                             max-height: 100px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }