@import '../global.scss';

.cofirm-acc-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // header
    .header {
        background-color: $app_theme;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        .logo-png {
            width: 75px;
            height: 55px;
        }
        .app-title {
            font-family: Pacifico;
            font-size: 30px;
            margin-left: 12px;
            color: rgb(255, 255, 255);
        }
    }

    .body {
        display: flex;
        justify-content: center;
        overflow-y: auto;
        .content {
            width: 50%;
            background-color: rgba(0, 0, 0, 0.04);
            box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 20px;
            gap: 20px;
            color: rgb(93, 93, 93);
            font-size: 14px;
            // text-align: center;
            padding: 25px;
            overflow-y: auto;

            .status-msg {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .bold-text {
                    font-weight: bold;
                }
                .heading {
                    font-size: 20px;
                    .rafflera-title {
                        font-family: Pacifico;
                        font-size: 30px;
                        margin-left: 12px;
                        color: $app_theme;
                    }
                }
                .msg-content {
                }
            }
            .btn {
                border: none;
                @include primary_button_sm;
            }
            .disabled-btn {
                opacity: 0.5;
                cursor: default;
            }
        }
    }
}

@media screen and (max-width: 720px) {

    ::-webkit-scrollbar {
        display: none;
    }
    .cofirm-acc-page .body .content {
        width: auto;
    }
}