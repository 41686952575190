

.monthly-raff-container{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .raffle-month-time {
        gap: 10px;
        display: flex;
        flex-direction: row;

        :nth-child(n) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            justify-content: center;

            .month-day-picker {
                gap: 0px;
                height: 30px;

                select {
                    height: 33px;
                }
            }
        }


    }
}


.monthly-series-sub-raffle {
    display: flex;
    flex-direction: row;
    // width: 100%;
    gap: 10px;

    .monthly-available-instances {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 10px;

        .monthly-select-all{
            font-weight: 700;
            text-align: left;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 13px;
        }

        >label {
            display: flex;
            align-items: center;
            >span{
                >input{
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
        .available-instances-title {
            text-align: left;
            font-weight: 700;
        }

        .occurrences {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 180px;
            overflow-y: auto;
            border: 0.1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 5px;
            gap: 15px;
            
            .years-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                >span{
                    font-size: 13px;
                    font-weight: 600;
                    .year-selected{
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }

            .available-months{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                .months-name{
                    font-size: 13px;
                }
            }

            .select-all {
                display: flex;
                flex-direction: column;
                gap: 8px;

                >span {
                    display: flex;
                    gap: 10px;
                    font-size: 14px;

                    >span {
                        width: auto;
                    }
                }

                .all-checkbox {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    // height: 100%;

                    .each-checkbox {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        font-size: 13px;
                        // margin-right: $margin_sm;
                        gap: 10px;
                        

                        >:nth-last-child(n) {
                            display: flex;
                            flex-wrap: wrap;

                            >span {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .monthly-selected-instances-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 10px; 

        .monthly-selected-instances {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 180px;
            overflow-y: auto;
            border: 0.1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 5px;
            grid-gap: 15px;
            gap: 15px;
        }
        .selected-hours {
            display: flex;
            flex-direction: column;
            grid-gap: 8px;
            gap: 8px;
            .selected-head{
                font-size: 14px;
            }
            .selected-content{
                display: flex;
                flex-direction: column;
                grid-gap: 5px;
                gap: 5px;
                .selected-items {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 13px;
                    grid-gap: 10px;
                    gap: 10px;

                    .months-name{
                        width: 100px;
                    }

                    .raff-times {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }
        }

        .selected-instances-title {
            text-align: left;
            font-weight: 700;
        }

        .instances-content {
            border: 0.1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            gap: 15px;
            overflow-y: scroll;
            height: 180px;
            box-sizing: border-box;
            padding: 5px;

            .selected-hours {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .selected-head {
                    font-size: 14px;
                }

                .selected-content {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    font-size: 13px;
                }
            }
        }
    }
}


@media screen and (max-width:720px) {
    .monthly-raff-container{
        .raffle-month-time {
            display: flex;
            flex-direction: column;
        }
        .monthly-series-sub-raffle{
            display: flex;
            flex-direction: column;
            .monthly-available-instances{
                width: 100%;
                .occurrences{

                    .select-all { 
                        >span{
                            font-size: 12px;
                        }
                        .all-checkbox {
                            .each-checkbox {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
            .monthly-selected-instances-container{
                width: 100%;
                .selected-hours {
                    .selected-head {
                        font-size: 12px;
                    }
                    .selected-content{
                        font-size: 10px;
                        .selected-items{
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}