@import '../../global.scss';

#qr_code_model{
    position: fixed;
    z-index: 9999;
    backdrop-filter: blur(3px);
    background-color: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // qr conatiner
    >div{
        // width: 300px;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;


        //header
        > div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            box-sizing: border-box;
            width: 100%;
            
            >:nth-child(1){
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
            .title-cup {
                // width: 120px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .title {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            
            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }

        // code
        >:nth-child(2){
            padding: 10px 20px;
            margin-top: 14px;
            min-width: 300px;
            min-height : 300px;
        }
        // download qr
        >:nth-child(3){
            margin-bottom: 14px;
            font-size: 14px;
            cursor: pointer;
            color: $app_text_color;
            text-decoration: underline;
            text-align: center;
        }
    }
}



@media screen and (max-width: 720px){
    #qr_code_model{
        >div{
            background-color: white;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            width: 85%;
            align-items: center;
            >div:nth-child(1){
                height: 55px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: white;
                background-color: rgba(23, 138, 167, 0.9);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                box-sizing: border-box;
                width: 100%;

                .title{
                    font-size: 16px;
                }

                .title-cup-container{
                    width: 133px;
                    font-weight: 500;
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                
                }
            }
           
        }
    }
}