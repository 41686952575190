.weekly-raffle {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .raffle-day-time {
        gap: 10px;
        display: flex;
        flex-direction: row;

        :nth-child(n) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            justify-content: center;

            .week-day-picker {
                gap: 0px;
                height: 30px;

                select {
                    height: 33px;
                }
            }
        }


    }

    .series-sub-raffle {
        display: flex;
        flex-direction: row;
        // width: 100%;
        gap: 10px;

        .available-instances {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 4px;

            .weekly-available-instances{
                font-weight: 700;
                text-align: left;
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 13px;
            }

            .available-instances-title {
                text-align: left;
                font-weight: 700;
            }

            .occurrences {
                .each-checkbox {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    align-items: center;
                    // margin-right: $margin_sm;
                    gap: 10px;
                }

                .all-checkbox {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    // height: 100%;

                    .each-checkbox {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        // margin-right: $margin_sm;
                        gap: 10px;


                        >:nth-last-child(n) {
                            display: flex;
                            flex-wrap: wrap;

                            >span {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }

        .selected-instances {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 4px;

            .selected-instances-title {
                text-align: left;
                font-weight: 700;
            }

            .instances-content {
                border: 0.1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                gap: 15px;
                overflow-y: scroll;
                height: 180px;
                box-sizing: border-box;
                padding: 5px;

                .selected-hours {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    .selected-head {
                        font-size: 14px;
                    }

                    .selected-content {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    // .instance-boxes {}
}


@media screen and (max-width:720px) {
    .weekly-raffle{
        .raffle-day-time{
            display: flex;
            flex-direction: column;
        }
    }
}