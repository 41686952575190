@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800&family=Rubik:wght@300;400;500;600;700&display=swap');
$theme_color:#00ACEE;
$theme_color_extra_light:#f5fcff;
$theme_secondary: rgba(0,0,0,0.1);
$text_color: rgba(0,0,0,0.7);
$base_margin: 20px;
$base_margin_md: 15px;
$base_margin_small: 10px;
$base_margin_xsmall: 5px;
$base_padding: 20px;
$base_padding_small: 10px;
$base_height: 40px;
$base_height_lg: 60px;
$base_radius: 20px;
$font-family: 'Heebo', sans-serif;
$large_icon: 23px;
$md_icon: 16px;
$border: 1px solid rgba(0,0,0,0.05);
$opacity: 0.6;
$regular_text:14px;
$small_text:12px;

@mixin box-shadow {
    box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
}

#collaborate{
    max-width: 100vw;
    max-height: 100vh;
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: 50px calc(100vh - 50px);

    >nav{
        grid-column: 1/3;
        background-color: #00ACEE;
        display: flex;
        align-items: center;
        padding: 0px $base_padding;
        color: white;
        // icon
        >:nth-child(1){
            font-size: $large_icon;
        }
        >label{
            font-family: $font-family;
            font-size: large;
            font-weight: 500;
            margin-left: $base_margin_small;
        }
        // profile
        >:nth-child(3){
            margin-left: auto;
            font-size: $large_icon;
        }
    }

    >aside{
        display: flex;
        flex-direction: column;
        border-right: $border;
        >.search{
            min-height: $base_height;
            background-color: $theme_color_extra_light;
            display: flex;
            justify-content: center;
            align-items: center;
            >.conatiner{
                width: 90%;
                height: 80%;
                background-color: white;
                display: flex;
                align-items: center;
                padding: 0px $base_padding_small;
                border-radius: $base_radius;
                box-sizing: border-box;
                @include box-shadow;
                // search icon
                >:nth-child(1){
                    font-size: $md_icon;
                    color: $text_color;
                }
                >input{
                    border: none;
                    outline: none;
                    font-family: $font-family;
                    margin-left: $base_margin_small;
                    flex: 1;
                }
                // sort button
                >:nth-last-child(1){
                    cursor: pointer;
                    position: relative;
                    // icon
                    >:nth-child(1){
                        font-size: $large_icon;
                    }
                    // sort list
                    >:nth-child(2){
                        width: 100px;
                        position: absolute;
                        top: 25px;
                        background-color: rgb(252, 252, 252);
                        padding: $base_padding;
                        @include box-shadow;
                        // items
                        >div{
                            font-size: small;
                            font-weight: 500;
                            margin-bottom: $base_margin_small;
                            color: $text_color;
                            &:hover{
                                cursor: pointer;
                                opacity: $opacity;
                            }
                        }
                    }
                }
            }
        }
        >.conatent{
            overflow-y: scroll;
            cursor: pointer;
            >div{
                height: $base_height_lg;
                border-bottom: $border;
                display: flex;
                align-items: center;
                padding: 0px $base_padding_small;

                // pic
                >:nth-child(1){
                    width: $base_height;
                    height: $base_height;
                    opacity: $opacity;
                }
                // content
                >:nth-child(2){
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0px $base_padding_small;
                    >strong{
                        font-size: $small_text;
                        font-family: $font-family;
                    }
                    >p{
                        font-size: $small_text;
                        font-family: $font-family;
                    }
                }
            }
        }
    }

    #conatiner{
        background-color: $theme_color_extra_light;
        display: flex;
        flex-direction: column;
        // header
        >:nth-child(1){
            height: $base_height;
            border-bottom: $border;
            display: flex;
            align-items: center;
            padding: 0px $base_margin;
            font-family: $font-family;
        }

        .no_receiever_selected{
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: large;
        }

        .receiever_selected{
            flex: 1;
            display: flex;
            flex-direction: column-reverse;
            >:nth-child(n){
                margin: $base_margin_md $base_margin;
                >label{
                    @include box-shadow;
                    padding: $base_padding_small $base_padding;
                }
            }

            .left_align{
                text-align: left;
                >label{
                    background-color: $theme_secondary;
                    border-top-right-radius: $base_radius;
                    border-bottom-right-radius: $base_radius;
                    color: $text_color;
                }
            }
            .right_align{
                text-align: right;
                >label{
                    background-color: $theme_color;
                    border-top-left-radius: $base_radius;
                    border-bottom-left-radius: $base_radius;
                    color: white;
                }
            }
        }
        // message type conatiner
        >:nth-child(3){
            min-height: $base_height_lg;
            background-color: white;
            border-top: $border;
            display: flex;
            align-items: center;
            padding: 0px $base_padding;
            >input{
                flex:1;
                border: 0px;
                outline: none;
            }
            // send icon
            >:nth-child(2){
                margin-left: $base_margin;
                font-size: $large_icon;
                color: $text_color;
            }
        }
    }

    #collaborate_loader{
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.1);
        backdrop-filter: blur(3px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}