@import "../../../global.scss";

.confirmation_dialouge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    .cancel-window {
        width: 85vw;
        height: 100vh;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        display: flex;
        // position: relative;
        // flex-wrap: wrap;
        flex-direction: column;
        align-items: center;

        //header
        >div:nth-child(1) {
            height: 55px;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;

            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            >:nth-child(1) {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }

            >div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }

            .x-btn-enabled {
                margin-left: auto;
                cursor: pointer;
                font-size: 16px;
                padding: 10px 10px;
                text-align: center;
                background-color: transparent;
                color: white;
                border-radius: 4px;
                // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                font-weight: bold;
                border-color: transparent;
            }

            .x-btn-disabled {
                margin-left: auto;
                font-size: 16px;
                padding: 10px 10px;
                text-align: center;
                background-color: transparent;
                color: rgba(255, 255, 255, 0.432);
                border-radius: 4px;
                // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                font-weight: bold;
                border-color: transparent;
            }
        }

        // Modal Contents
        >div:nth-child(2) {
            height: calc(100% - 115px);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            // margin-bottom: 30px;
            overflow-y: auto;

            .cancel-note {
                margin-top: auto;
                font-size: 15px;
                padding: 0px 10px 0px 10px;
            }
        }

        //footer
        >div:nth-last-child(1) {
            width: 100%;
            height: 60px;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            // flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            // position: absolute;
            bottom: 0;
            box-sizing: border-box;
            margin-top: auto;

            .draw-btn {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: $app_theme;
                color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                margin-left: 20px;
                font-weight: bold;
                border-color: transparent;
            }

            .draw-btn-disabled {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: $app_theme;
                color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                // cursor: pointer;
                margin-left: 20px;
                font-weight: bold;
                border-color: transparent;
                opacity: 0.5;
            }

            .close-btn {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: white;
                color: black;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                font-weight: bold;
                margin-left: 20px;
                border-color: transparent;
            }

            .close-btn-disabled {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.4);
                color: rgb(255, 255, 255);
                border-radius: 4px;
                margin-left: 20px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                // cursor: pointer;
                opacity: 0.5;
                font-weight: bold;
                border-color: transparent;
            }

            .notify_checkbox {
                display: flex;
                gap: 10px;
                justify-content: flex-start;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .confirmation_dialouge {
        .cancel-window {
            width: 90vw;
            height: 90vh;
            background-color: white;
            box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;

            >div:nth-child(1) {
                height: 40px;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-left: 20px;
                padding-right: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: white;
                background-color: rgba(23, 138, 167, 0.9);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                >div:nth-child(2) {
                    font-weight: 500;
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            .cancel-window-content {
                overflow: hidden;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .cancel-window-ticket {
                    // width: 100%;
                }

                .cancel-note {
                    margin-top: auto;
                    font-size: 13px;
                    padding: 0px 10px 0px 10px;
                }
            }

            >div:nth-last-child(1) {
                width: 100%;
                height: 68px;
                background-color: rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                display: flex;
                flex-direction: row-reverse;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                bottom: 0;
                box-sizing: border-box;
                margin-top: auto;

                .btn-container {
                    display: flex;
                    grid-gap: 10px;
                    gap: 10px;
                    justify-content: flex-start;
                    font-size: 12px;
                }

                .notify_checkbox {
                    display: flex;
                    grid-gap: 10px;
                    gap: 10px;
                    justify-content: flex-start;
                    font-size: 12px;
                }

                .close-btn {
                    font-size: 10px;
                    padding-top: 7.5px;
                    padding-bottom: 7.5px;
                    padding: 10px 30px;
                    text-align: center;
                    background-color: white;
                    color: black;
                    border-radius: 4px;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    font-weight: bold;
                    margin-left: 20px;
                    border-color: transparent;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .draw-btn {
                    font-size: 10px;
                    padding-top: 7.5px;
                    padding-bottom: 7.5px;
                    padding: 10px 30px;
                    text-align: center;
                    background-color: rgba(23, 138, 167, 0.9);
                    color: white;
                    border-radius: 4px;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    margin-left: 20px;
                    font-weight: bold;
                    border-color: transparent;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

        }
    }
}