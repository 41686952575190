#raffle_ticket {

    // ticket contnt
    >:nth-child(2) {
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 5px;
        top: 0px;
        left: 0px;

        >:nth-child(1) {
            height: 90%;
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            border: 1px solid white;
            border-radius: 40px;
            gap: 5px;
            box-sizing: border-box;
            padding: 5px 0px;
            font-size: 12px;

            >h4 {
                // display: flex;
                // justify-content: center;
                // align-items: center;
                height: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
                font-size: 1.2em;
                height: 15px;
                text-transform: uppercase;
                width: 80%;
                overflow: hidden;
            }
    
            >:nth-child(2) {
                display: flex;
                flex: 1;
                width: 100%;
                gap: 10px;
                box-sizing: border-box;
                padding-left: 10px;
                height: calc(100% - 30px);
                justify-content: center;
                align-items: center;
    
                >:nth-child(1) {
                    width: 30%;
                    height: 90%;
                    
                    >img {
                        height: 75%;
                        width: 100%;
                        border-radius: 20px;
                        // object-fit: contain;
                    }
                }
    
                >div {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    justify-content: center;
                    flex-grow: 1;
                    // padding-right: 10px;
    
                    >div {
                        font-size: small;
                        font-weight: 500;
                        display: flex;
                        gap: 5px;

                        // >:nth-child(1) {
                        //     font-weight: bold;
                        // }
                    }
                }
            }
    
            >:nth-child(3) {
                height: 15px;
                >p {
                    text-align: center;
                    font-size: small;
                    font-weight: 500;
                }
            }
        }


    }
}

.shortdesc {
    max-width: 50%;
    word-break: break-all;
}

@media screen and (max-width: 720px) {
    #raffle_ticket {
        >:nth-child(2) {
            height: 100%;
            width: 100%;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 16px;
            top: 0px;
            left: 0px;
            // margin-top: 18px;
            // padding-top: 20px;
            // padding-bottom: 40px;
            > :nth-child(1) {
                height: 100%;
                width: 93%;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid white;
                border-radius: 40px;
                grid-gap: 5px;
                gap: 5px;
                box-sizing: border-box;
                padding: 5px 0px;
                font-size: 12px;
                padding-right: 8px;
                >h4 {
                    height: 16px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                    font-size: 10px;
                    text-transform: uppercase;
                    width: 80%;
                    // padding-top: 20px;
                }
                >:nth-child(1) {
                    >:nth-child(2) {
                        >:nth-child(1) {

                        }
                    }
                }
                >:nth-child(2) {
                    display: flex;
                    flex: 1 1;
                    width: 100%;
                    grid-gap: 0px; 
                    gap: 0px; 
                    box-sizing: border-box;
                    padding-left: 6px;
                    height: calc(100% - 30px);
                    justify-content: center;
                    align-items: center;
                    > div{
                        font-size: 10px;
                        font-weight: 500;
                        display: flex;
                        padding-left: 1px;

                        >div{
                            font-size: 10px;
                            font-weight: 500;
                            display: flex;
                            grid-gap: 5px;
                            gap: 5px;
                        }
                    }
                    >:nth-child(1){
                        >img {
                            height: 100%;
                            border-radius: 20px;
                            width: 90%;
                        }
                    }
                   
                }
                >:nth-child(3){
                    // padding-bottom: 20px;
                    >p{
                        text-align: center;
                        font-size: 10px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}