@import "./global.scss";

.invitations-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 10px;

  .sub-header {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    justify-content: end;
    gap: 15px;

    .search-container {
      padding: 0px 1px;
    
      .inv-input-wrapper {
        height: 40px;
        //width: 96.57%;
        background-color: white;
        margin-top: 6px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        padding-left: 10px;
    
        .search-here{
          display: flex;
          justify-content: flex-start;
          width: 100%;

          .host-search-bar {
            width: 100%;
            padding-left: 10px;
            border: 0px;
            font-size: 13px;
            color: $app_text_color;
            opacity: 0.7;

            &:focus {
                outline-width: 0px;
            }
        }

          .host-date-filter {
            margin-right: auto;
            border: 0px;
            padding-left: 5px;

            &:focus {
                outline-width: 0px;
            }
        }
        }
        .sortby{
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    
      
    
      .search-x-btn {
        border-width: 0px;
        min-height: 30px;
        min-width: 30px;
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 50%;
        margin-right: 10px;
        transition: background-color 0.3s ease;
      }
    
      .search-x-btn:hover {
        opacity: 0.3;
      }
    
      .inv-search-btn {
        @include primary_button_sm;
        border: none;
        margin-right: 10px;
      }
    
      .inv-search-btn-dsbld {
        @include primary_button_sm_disabled;
        border: none;
        margin-right: 10px;
      }
    
      .search-btn.active {
        background-color: rgb(23, 138, 167);
        color: #fff;
        border-color: rgb(23, 138, 167);
      }
    
      .select {
        height: 40px;
        border: 0px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        outline: none;
        opacity: 0.7;
        width: 200px;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
  
    .categories {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      

      .inv-page{
        display: flex;
        justify-content: center;
        align-items: center;
        // flex: 1 1;
        text-align: center;
        line-height: 28px;
        font-weight: 450;
        font-size: medium;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 50px;
        cursor: pointer;
        // background-color: rgb(23, 138, 167);
        color: $app_theme;
        font-weight: 600;
      }
      .items{
        margin-left: 20px;
        display: flex;
      }
    }
  }
}



.notif-loader {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.list-view {
  flex: 1;
  overflow-y: auto;
  // height: 300px;
}

.no-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.refresh-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-right: 10px;
}

.notification-header {
  color: black;
  font-size: 20px;
  font-weight: 550;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // flex: 1;
  height: 60px;
}

.switch-tabs {
  color: $app_text_color;
  font-size: 20px;
  font-weight: 550;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  // align-items: center;
  flex: 1 1;
}

.tab-bar {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 50px;
}

.active-item {
  background-color: rgb(23, 138, 167);
  color: white;
}


.notification-name-host {
  flex: 1 1;
  text-align: center;
  line-height: 28px;
  // color: black;
  font-weight: 450;
  font-size: medium;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 50px;
  cursor: pointer;
  width: 530px;
}

.notification-name {
  flex: 1 1;
  text-align: center;
  line-height: 28px;
  // color: black;
  font-weight: 450;
  font-size: medium;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 50px;
  cursor: pointer;
  width: 530px;
}

.table-container {
  // margin-top: 10px;
  border-collapse: collapse;
  width: 100%;

}

th,
td {
  padding: 8px;
  text-align: left;
  font-family: $font_family_1;
  border-bottom: 1px solid #ddd;
  font-size: 13px;
}

.table-head {
  background-color: #f3f3f3;
  font-family: "Roboto";
  color: rgba(39, 37, 37, 0.67);
  position: sticky;
  top: 0;
 

  .header-row {
    th {
      position: sticky;
      top: 0px;
      background-color: lightgrey;
    }

    .snum {
      width: 3%;
      font-size: 14px;
    }

    .raff-title {
      width: 16%;
      font-size: 13px;
    }

    .host-name {
      width: 13%;
      font-size: 13px;
    }

    .from {
      width: 13%;
      font-size: 13px;
    }

    .check-box {
      width: 4%;
      font-size: 13px;
    }

    .sub {
      text-align: left;
      width: 20%;
      font-size: 13px;
    }

    .time-stamp {
      text-align: right;
      width: 17%;
      font-size: 13px;
    }
  }
}



.table-body {

  .actions{
    .eye:hover {
      color: $app_theme;
    }
    .bulls-eye:hover{
      color: $app_theme;
    }
  }
  .tb-row>.td-time-stamp {
    text-align: right;
  }
}

tbody tr:nth-child(even) {
  // background-color: #f9f9f9;
}



caption {
  font-weight: bold;
  margin-bottom: 10px;
}

.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px;

  button {
    margin-right: 8px;
  }
}

.dlt-btn {
  margin-right: 20px;
  margin-top: 10px;
  background-color: rgb(23, 138, 167);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 8px 20px;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  text-align: center;
  border-width: 0px;
  height: 32px;
  cursor: pointer;

  :hover {
    opacity: 0.2;
  }
}

.trash-btn {
  color: white;
  background-color: rgb(23, 138, 167);
  font-family: $font_family_1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0px;
  height: 30px;
  width: 128px;
  cursor: pointer;
}

.enablebtn {
  display: flex;
}

.disablebtn {
  margin-right: 20px;
  margin-top: 10px;
  background-color: rgb(23, 138, 167);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 8px 20px;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  text-align: center;
  border-width: 0px;
  height: 32px;
  opacity: 0.5;
  ;
}

.refresh-icon {
  // width: 24px;
  // height: 24px;
  color: rgba(39, 37, 37, 0.67);
  margin-left: 10px;
  cursor: pointer;
}

.refresh-icon:hover {
  color: rgb(23, 138, 167);
}

.notif-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin-top: auto;

  .itemsperpage {
    width: 200px;

    select {
      border: 0px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 8px;
      font-size: small;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .pagination {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    text-overflow: ellipsis;
    grid-gap: 10px;
    gap: 10px;

    .pagination-number {
      border-width: 0px;
      min-height: 40px;
      min-width: 40px;
      cursor: pointer;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 50%;
      transition: background-color 0.3s ease;
    }

    .page-num {
      border-width: 0px;
      min-height: 40px;
      min-width: 40px;
      cursor: pointer;
      background-color: #f5f5f5;
      border: 1px solid #ddd;
      border-radius: 50%;
      transition: background-color 0.3s ease;
    }

    .ellipsis {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    button:hover {
      background-color: rgb(23, 138, 167);
      color: white;
      opacity: 0.3;
    }

    button.active {
      background-color: rgb(23, 138, 167);
      color: #fff;
      border-color: rgb(23, 138, 167);
    }

    .dots {
      padding: 8px 12px;
      color: #333;
    }
  }

  .empty-container {
    width: 200px;
  }
}


.active2 {
  display: flex;
  background-color: $app_theme_active;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  height: 16px;
  width: 80px;
  opacity: 0.9;
  padding: 8px;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  font-weight: 500;
  font-size: small;
  border: 1px solid rgba(0, 0, 0, 0.1);
  // margin-left: 10px;
  cursor: pointer;
}

// .active2:hover {
//   opacity: 0.2;
// }

.inactive1 {
  display: flex;
  background-color: white;
  color: black;
  border-radius: 5px;
  font-size: 14px;
  height: 16px;
  width: 80px;
  opacity: 0.9;
  padding: 8px;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  font-weight: 500;
  font-size: small;
  border: 1px solid rgba(0, 0, 0, 0.1);
  // margin-left: 10px;
  cursor: pointer;
}

.inactive:hover {
  color: $app_theme_active;
}


.notifsub {
  max-width: 50%;
  word-break: break-all;
}

.trashbtns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 20px;

  >div>button {
    margin-right: 0px;
  }
}

.restorebtn {
  margin-right: 20px;
  background-color: rgb(23, 138, 167);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 8px 20px;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  text-align: center;
  border-width: 0px;
  height: 32px;
  cursor: pointer;
}

.disablebtn2 {
  margin-right: 20px;
  background-color: rgb(23, 138, 167);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 8px 20px;
  border-radius: 6px;
  font-size: small;
  font-weight: 500;
  text-align: center;
  border-width: 0px;
  height: 32px;
  cursor: pointer;
  opacity: 0.5;
}






@media screen and (max-width: 720px) {
  .invitations-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 10px;
    overflow-x: hidden;

    .sub-header {
      gap: 10px;
      .categories {
        display: flex;
        // justify-content: center;
        height: auto;
        overflow-x: scroll;
        
        .inv-page{
          font-size: 12px;
        }
        .refresh-icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: left;
          margin-right: 10px;
        }
  
        .active2 {
          display: flex;
          background-color: rgb(23, 138, 167);
          color: white;
          border-radius: 5px;
          font-size: 14px;
          height: 35px;
          width: 90px;
          opacity: 0.9;
          padding: 8px;
          align-items: center;
          justify-content: center;
          line-height: 25px;
          font-weight: 500;
          font-size: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;
          box-sizing: border-box;
          height: 20px;
        }
  
        .inactive1 {
          display: flex;
          background-color: white;
          color: black;
          border-radius: 5px;
          font-size: 14px;
          height: 35px;
          width: 90px;
          opacity: 0.9;
          padding: 8px;
          align-items: center;
          justify-content: center;
          line-height: 25px;
          font-weight: 500;
          font-size: 10px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;
          box-sizing: border-box;
          height: 20px;
        }
      }
      .search-container {
        width: 98%;
        height: 35px;
    
        .input {
          width: 100%;
          padding-left: 10px;
          border: 0px;
          font-size: 10px;
          color: black;
        }
    
        .inv-input-wrapper {
          max-width: 100vw;
          position: static;
          height: 35px;
        }
    
        .search-x-btn {
          border-width: 0px;
          min-height: 11px;
          min-width: 11px;
          cursor: pointer;
          background-color: #f5f5f5;
          border-radius: 50%;
          margin-right: 10px;
          transition: background-color 0.3s ease
        }
    
        .inv-search-btn {
          background-color: rgb(23, 138, 167);
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          color: white;
          padding: 8px 8px;
          border-radius: 6px;
          font-size: 10px;
          font-weight: 500;
          text-align: center;
          cursor: default;
          opacity: 0.5;
          border: none;
          margin-right: 0px;
        }
    
        .inv-search-btn-dsbld {
          background-color: rgb(23, 138, 167);
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
          color: white;
          padding: 8px 8px;
          border-radius: 6px;
          font-size: 10px;
          font-weight: 500;
          text-align: center;
          cursor: default;
          opacity: 0.5;
          border: none;
          margin-right: 0px;
        }
    
        .select {
          height: 35px;
          width: 90px;
          // border: 1px solid rgba(0, 0, 0, 0.1);
          display: flex;
          border-radius: 12px;
          margin-left: 5px;
        }
      }
    }
  }

  .list-view {
    flex: 1 1;
    overflow-y: auto;
    height: 300px;
    font-size: 10px;
  }





  .notification-name-host {
    .notification-name {
      width: 100%;
      max-width: 100%;
      font-size: 14px;
    }

    // .table-head .header-row {
    //     display: none;
    // }

    // .table-head .header-row th {
    //     display: none;
    // }

  }

  .table-head {
    .header-row {
      th {
        position: sticky;
        top: 0px;
        background-color: lightgrey;
      }

      .snum {
        width: 3%;
        font-size: 14px;
      }

      .raff-title {
        width: 16%;
        font-size: 10px;
      }

      .host-name {
        width: 13%;
        font-size: 10px;
      }

      .from {
        width: 13%;
        font-size: 10px;
      }

      .check-box {
        width: 4%;
        font-size: 10px;
      }

      .sub {
        text-align: left;
        width: 20%;
        font-size: 10px;
      }
      .closesat{
        text-align: left;
        width: 20%;
        font-size: 10px;
      }

      .time-stamp {
        text-align: right;
        width: 17%;
        font-size: 10px;
      }
    }
  }

  th,
  td {
    padding: 5px;
    text-align: left;
    font-family: "Roboto", sans-serif;
    border-bottom: 1px solid #ddd;
    font-size: 10px;
  }

  .notif-footer {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    height: 50px;
    margin-top: auto;
    margin-bottom: 30px;

    .itemsperpage {
      margin-right: auto;
      width: 200px;
      font-size: 12px;
      margin-top: -12px;

      select {
        border: 0px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 3px;
        font-size: small;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .pagination {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: hidden;
      flex-wrap: wrap;
      text-overflow: ellipsis;
      margin-bottom: -6px;
      gap: 3px;

      .pagination-number {
        border-width: 0px;
        min-height: 30px;
        min-width: 30px;
        cursor: pointer;
        // padding: 5px 10px;
        background-color: #f5f5f5;
        border: 1px solid #ddd;
        border-radius: 50%;
        transition: background-color 0.3s ease;
      }

      .ellipsis {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }

      button:hover {
        background-color: rgb(23, 138, 167);
        color: white;
        opacity: 0.3;
      }

      button.active {
        background-color: rgb(23, 138, 167);
        color: #fff;
        border-color: rgb(23, 138, 167);
      }

      .dots {
        padding: 8px 12px;
        color: #333;
      }
    }

    .empty-container {
      width: 200px;
    }
  }

  .disablebtn {
    margin-top: 10px;
    background-color: rgb(23, 138, 167);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    border-width: 0px;
    height: 32px;
    opacity: 0.5;
  }

  .trashbtns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;
    margin-bottom: 20px;

    >div>button {
      margin-right: 0px;
      font-size: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 10px;
      margin-right: 10px;
    }
  }
}