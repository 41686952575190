@import "../../global.scss";

.customize_raffle_view {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
        width: 400px;
        min-height: 300px;
        background-color: white;
        border-radius: 4px;
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        //header
        > div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            >:nth-child(1){
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
            > div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }
            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }

        //select box items
        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            margin-top: auto;
            padding: 10px 20px;
            > select {
                margin-top: 6px;
                margin-bottom: 12px;
                height: 30px;
                font-size: 13px;
                color: rgba(0, 0, 0, 0.8);
            }
            > label {
                color: $app_text_color;
                font-size: small;
            }
        }

        // private raffle checkbox
        >div:nth-child(3){
            margin-bottom: auto;
            display: flex;
            align-items: center;
            padding: 0px 20px;
            // text
            >:nth-child(2){
                font-size: small;
                color: $app_text_color;
                margin-left: 4px;
            }
        }

        //footer
        > div:nth-last-child(1) {
            height: 60px;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            // create raffle button
            > div:nth-child(1) {
                margin-right: 10px;
                @include primary_button_sm;
            }
            > div:nth-child(2) {
                margin-right: 20px;
                @include light_button_sm;
            }
        }
    }
}
