@import "../../../global.scss";

.lbl-input-field {
    display: flex;
    flex-direction: column;
    color: $app_text_color;
    width: 100%;

    >input {
        border: 0px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 8px;
        font-size: small;
        min-height: 15px;
    }
}