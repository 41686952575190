@import "../../../global.scss";

.Confirm-delete {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    .container1 {
        display: "flex";
        flex-direction: "column";
        justify-content: "center";
        align-items: "center";
        padding: 0 0 0 0;

        .container1-child1 {
            display: flex;
            justify-content: center;
            align-items: center;
           

            .container1-child1-child1 {
                top: 250px;
                display: flex;
                position: relative;
                box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
                flex-direction: column;
                z-index: 99;
                width: 550px;
                border-radius: 6px;

                .header {
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    box-sizing: border-box;
                    padding-left: 10px;
                    padding-right: 20px;
                    height: 50px;
                    width: 100%;
                    background-color: rgba(23, 138, 167);
                    text-transform: uppercase;
                    font-weight: 500;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .x-close-btn {
                        margin-left: auto;
                        cursor: pointer;
                        font-size: 16px;
                        // padding: 10px 10px;
                        text-align: center;
                        background-color: transparent;
                        color: white;
                        border-radius: 4px;
                        // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                        font-weight: bold;
                        border-color: transparent;
                    }

                    .del-cup{
                        width: 26px;
                        height: 26px;
                        margin-right: 10px;
                    }
                }
                .selecteditems-container {
                    box-sizing: border-box;
                    height: 170px;
                    width: 100%;
                    background-color: rgb(255, 255, 255);
                    text-transform: uppercase;
                    font-weight: 500;
                    color: rgb(25, 25, 25);
                    display: flex;
                    justify-content: center;
                    padding-left: 10px;
                    overflow-y: auto;
                }
                .footer {
                    border-bottom-left-radius: 6px;
                    border-bottom-right-radius: 6px;
                    box-sizing: border-box;
                    height: 50px;
                    width: 100%;
                    background-color: rgb(209, 209, 209);
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    padding-right: 20px;
                    // gap: 10px;

                    .close-btn {
                        font-size: 13px;
                        padding: 6px 20px;
                        text-align: center;
                        background-color: white;
                        color: black;
                        border-radius: 4px;
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                        font-weight: bold;
                        border-color: transparent;
                    }

                    .confirm-btn {
                        font-size: 13px;
                        padding: 6px 20px;
                        text-align: center;
                        background-color: $app_theme;
                        color: white;
                        border-radius: 4px;
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                        margin-left: 20px;
                        font-weight: bold;
                        border-color: transparent;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 720px){
    .Confirm-delete{

        .container1{

            .container1-child1{

                .container1-child1-child1{
                    top: 250px;
                    display: flex;
                    position: relative;
                    box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
                    flex-direction: column;
                    z-index: 99;
                    width: 70%;
                    border-radius: 6px;
                    .header{
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                        box-sizing: border-box;
                        padding-left: 20px;
                        padding-right: 20px;
                        height: 50px;
                        width: 100%;
                        background-color: rgb(23, 138, 167);
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 13px;
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        
                    }

                    .body{
                        box-sizing: border-box;
                        height: 70px;
                        width: 100%;
                        background-color: rgb(255, 255, 255);
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 10px;
                        color: rgb(25, 25, 25);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-left: 10px;
                    }
                    .footer{

                        .close-btn{
                            font-size: 10px;
                            padding: 6px 20px;
                            text-align: center;
                            background-color: white;
                            color: black;
                            border-radius: 4px;
                            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                            cursor: pointer;
                            font-weight: bold;
                            border-color: transparent;
                        }
                        .confirm-btn{
                            font-size: 10px;
                            padding: 6px 20px;
                            text-align: center;
                            background-color: rgba(23, 138, 167, 0.9);
                            color: white;
                            border-radius: 4px;
                            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                            cursor: pointer;
                            margin-left: 20px;
                            font-weight: bold;
                            border-color: transparent;
                        }
                    }
                }
            }
        }
    }
}