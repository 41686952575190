@import "../../global.scss";

.customizeRaffleTicket {

    // body
    >div {
        padding: $margin_sm;
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        gap: 20px;
        flex: 1;

        // properties
        >:nth-child(1) {
            display: flex;
            flex-direction: column;
            gap: 10px;

            

            >.properties1 {
                @include tabs_sm;
                border: 0;

                >:nth-child(n) {
                    cursor: pointer;
                    font-size: 12px;
                    border: 0;
                }

                .active {
                    background-color: $app_text_color;
                    color: white;
                }
            }

            >.properties2 {
                display: flex;
                flex-direction: row;
                gap: $margin_md;

                >div {
                    display: flex;
                    flex-direction: column;

                    >label {
                        color: $app_text_color;
                        font-size: 12px;
                    }

                    >select {
                        padding: 4px 2px;
                        width: 150px;
                    }

                    >input {
                        padding: 4px 2px;
                        width: 50px;
                    }
                }
            }


            >:nth-child(3) {

                // box-shadow: none;
                // margin-right: auto;
                // compact color picker
                >:nth-child(1) {
                    box-shadow: none;
                    margin-right: auto;
                }

                // color picker
                >:nth-child(2) {
                    >:nth-child(n) {
                        min-height: 20px;
                    }
                }

            }

        }

        // live ticket view and rafflera disclosure
        >:nth-child(2) {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.03);

            // preview text
            >:nth-child(1) {
                // opacity: 0.4;
                font-weight: bold;
                color: $app_text_color;
            }

            // ticket
            > :nth-child(2) {
                // margin-top: 30px;
                // width: 250px;
                // height: 160px;
                position: relative;
                cursor: pointer;
                // margin-bottom: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;

                //raffle ticket image
                > :nth-child(1) {
                    width: 100%;
                    height: 100%;
                    // transform: rotateY(90deg);
                    transition: all 0.3s ease-in-out 0.3s;
                }

                >:nth-child(2) {
                    width: 256px;
                    height: 160px;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    // transform: rotateY(0deg);
                    transition: all 0.3s ease-in-out;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    >img {
                        width: 40px;
                        height: 30px;
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }
                }

                // > div {
                //     position: absolute;
                //     width: 256px;
                //     height: 120px;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;
                //     font-weight: bold;
                //     opacity: 0.7;
                //     top: 0px;
                //     left: 0px;
                //     > label {
                //         font-size: 18px;
                //         text-align: center;
                //     }
                //     > label:nth-child(2) {
                //         font-size: 0px;
                //         text-align: center;
                //     }
                // }
                // &:hover {
                //     > img:nth-child(1) {
                //         transform: rotateY(0deg);
                //     }
                //     > img:nth-child(2) {
                //         transform: rotateY(-90deg);
                //     }
                //     > div {
                //         > label:nth-child(1) {
                //             font-size: 0px;
                //         }
                //         > label:nth-child(2) {
                //             transition: all 0.3s ease-in-out 0.4s;
                //             font-size: 11.5px;
                //             padding: 20px;
                //         }
                //     }
                // }
            }

            >.disclosure {
                width: 100%;
                margin-top: auto;
                background-color: rgba(0, 0, 0, 0.1);
                padding: $margin_md;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                >p {
                    font-size: 14px;
                    color: $app_text_color;
                }
            }
        }

        // tickets price and limit per user
        >:nth-child(3) {
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-radius: 10px;

            .crm_currency {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .ticket-price {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .fee-switch {
                        display: flex;
                        flex-direction: row;
                        /* justify-content: center; */
                        align-items: center;
                        gap: 5px;
                    }

                    .currency-amount {
                        width: 180px;

                        >select {
                            width: 110px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            border-top-right-radius: 0px;
                            border-bottom-right-radius: 0px;
                        }

                        >input {
                            width: 70px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            border-top-left-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-left-width: 0px;
                            max-width: 50px;
                        }
                    }
                }
            }

            .ticket-limit {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: 180px;

                >input {
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    padding: 8px;
                    font-size: small;
                    height: 17px;
                }
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .customizeRaffleTicket{
        >div{
            padding: 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            grid-gap: 20px;
            gap: 20px;
            flex: 1 1;
            overflow-y: scroll;
        }
    }
}