@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$app_theme: rgba(23, 138, 167, 0.9);
$app_theme_active: rgba(23, 138, 167, 1);
$app_text_color: black;
// $app_text_color: rgb(17, 74, 90);
$shadow_color: rgba(0, 0, 0, 0.5);
$light_shadow_color: rgba(0, 0, 0, 0.3);
$nav_text: rgb(3, 18, 22);
$font_family_1: 'Roboto', sans-serif;
$font_family_2: Pacifico;
$switch_disable_color: rgb(255, 255, 255);
$switch_disable_background_color: rgb(210, 210, 210);

* {
    scroll-behavior: smooth;
    font-family: $font_family_1;
    margin: 0px;
    padding: 0px;
}

body{
    overflow-x: hidden;
    overflow-y: hidden;
}

// height
$base_height: 40px;
$base_height_m: 50px;

// colors
$secondary_color: #F6C729;
$light_grey_color: rgba(0,0,0,0.1);
$third_color: #B12683;

// for margins
$margin_xxs:3px;
$margin_xs:5px;
$margin_sm:10px;
$margin_xmd:15px;
$margin_md:20px;
$margin_xm:25px;
$margin_lg:30px;
$margin_sxl:40px;
$margin_xl:80px;
$margin_xxl:100px;

// for opacity
$opacity_light: 0.9;

// line height
$line_height_md:28px;
$line_height_sm: 25px;

// radius
$base_radius: 6px;

// text styles
@mixin text_md {
    line-height: $line_height_md;
    color: $app_text_color;
    font-weight: 450;
    font-size: medium;
}

@mixin text_lg {
    line-height: $line_height_md;
    color: $app_text_color;
    font-weight: 500;
    font-size: large;
}

@mixin text_xl {
    line-height: $line_height_md;
    color: $app_text_color;
    font-weight: 500;
    font-size: x-large;
}

@mixin text_xxl {
    line-height: $line_height_md;
    color: $app_text_color;
    font-weight: 500;
    font-size: 30px;
}

@mixin text_sm {
    line-height: $line_height_sm;
    color: $app_text_color;
    font-weight: 500;
    font-size: small;
}

// buttons
@mixin  primary_button {
    background-color:$app_theme_active;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

// buttons
@mixin  instant_raffles_to_be_drawn_button {
    background-color: $secondary_color;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: $app_text_color;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin primary_button_sm {
    background-color:$app_theme_active;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin primary_button_sm_disabled {
    background-color:$app_theme_active;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: default;
    opacity: 0.5;
}

@mixin secondary_button {
    background-color: $secondary_color;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: $app_text_color;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin secondary_button_sm {
    background-color: $secondary_color;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: $app_text_color;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin third_button {
    background-color: $third_color;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin third_button_sm {
    background-color: $third_color;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: white;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin third_button_xs {
    background-color: $third_color;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
    color: white;
    padding: 4px 16px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin light_button {
    background-color:white;
    box-shadow: 0px 0px 3px rgb(177, 177, 177);
    color: $app_text_color;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin light_button_sm {
    background-color:white;
    box-shadow: 0px 0px 3px rgb(177, 177, 177);
    color: $app_text_color;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin light_button_md {
    background-color:white;
    box-shadow: 0px 0px 3px rgb(177, 177, 177);
    color: $app_text_color;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: medium;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin light_button_xs {
    background-color:white;
    box-shadow: 0px 0px 3px rgb(177, 177, 177);
    color: $app_text_color;
    padding: 4px 16px;
    border-radius: 6px;
    font-size: small;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
}

@mixin tabs_sm {
    display: flex;
    align-items: center;
    color: $app_text_color;
    border-radius: 6px;
    font-size: small;
    font-weight: bold;
    background-color: rgba(0,0,0,0.05);
    >:nth-child(n){
        padding: 8px 12px;
        border-radius: 6px;
    }
}