@import "../../global.scss";

.ticket-view {
    display: flex;
    justify-content: center;
    align-items: center;
}

.faded-ticket-view {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}
.tabs-content::-webkit-scrollbar {
    width: 6px;
}

.tabs-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
}

.tabs-content::-webkit-scrollbar-thumb {
    background-color: #00000057;
    border-radius: 10px;
}

.content::-webkit-scrollbar {
    width: 6px;
}

.content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
    background-color: #00000057;
    border-radius: 10px;
}

//main view
.raffle_view {
    width: 1100px;
    max-height: 600px;
    position: relative;

    // left: -40px;
    // top: -30px;
    // box-sizing: border-box;
    //background image
    > :nth-child(1) {
        width: 1100px;
        height: 600px;
    }

    //content
    >div:nth-child(2) {
        width: 100%;
        overflow-y: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 1);
        padding: 30px 120px 30px 120px;
        box-sizing: border-box;
        gap: 10px;

        //title
        .hosted-title {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .short-description {
                font-size: 14px;
                text-align: center;
                font-weight: 400;
            }

            .hosted-sub-title {
                font-size: 16px;
                font-weight: 400;
                // font-weight: bold;
                opacity: 0.7;
                display: flex;
                justify-content: center;
            }
        }


        //body
        .hosted-body {
            display: flex;
            gap: 10px;
            overflow-y: hidden;
            width: 100%;

            //Tabs box
            .raffle-view-tabs {
                width: 65%;

                .tabs-header {
                    display: flex;
                    justify-content: space-between;
                    border-radius: 5px 5px 0px 0px;

                    :nth-child(1) {
                        border-radius: 5px 0px 0px 0px;
                    }

                    :last-child {
                        border-radius: 0px 5px 0px 0px;
                    }

                    :nth-child(n) {
                        // background-color: rgb(255, 0, 119);
                        border-width: 1px;
                        border-color: whitesmoke;
                        flex: 1;
                        padding: 5px 0px;
                        cursor: pointer;
                        font-weight: bold;
                        background-color: #ffffff73;
                        font-size: 13px;
                    }

                    .activeTab {
                        font-size: 13px;
                        background-color: #ffeff7;
                    }

                }

                .tabs-content {
                    height: 354px;
                    background-color: rgba(245, 245, 245, 0.4);
                    padding: 10px 10px;
                    padding-top: 15px;
                    border-radius: 0px 0px 5px 5px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;

                    //For Details Tab
                    .details-content {
                        // height: calc(100% - 24px);

                        >.detail {
                            padding-bottom: 10px;
                            // flex: 1;
                            display: flex;
                            font-size: 13px;
                            gap: 10px;

                            // font-weight: bold;
                            >.lable {
                                font-weight: bold;
                                // width: 40%;
                                // padding-right: 8px;
                            }

                            >.value {
                                // width: 60%;
                                margin-top: auto;
                            }
                        }
                    }

                    //For Prizes Table Tab
                    .prizes-table {
                        >table {
                            width: 100%;
                            border-collapse: collapse;

                            >thead {
                                border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
                                font-size: 14px;
                                background-color: rgb(25 25 25 / 20%);
                            }

                            >tbody {
                                font-size: 14px;

                                >tr {
                                    border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
                                    .drawnum {
                                        font-weight: bold; 
                                        // background-color: rgba(225, 225, 225, 0.3);
                                        text-align: left;
                                    }
                                    >td {
                                        // line-height: 30px;
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }

                    .draws-list {
                        >table {
                            width: 100%;
                            border-collapse: collapse;

                            >thead {
                                border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
                                font-size: 14px;
                            }

                            >tbody {
                                font-size: 14px;

                                >tr {
                                    border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);

                                    >td {
                                        text-align: center;
                                        // line-height: 30px;
                                    }
                                }
                            }
                        }
                    }

                    .participants {
                        >table {
                            width: 100%;
                            border-collapse: collapse;

                            >thead {
                                border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
                                font-size: 14px;
                            }

                            >tbody {
                                font-size: 14px;

                                >tr {
                                    border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);

                                    >td {
                                        // line-height: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //Logo and Description column
            .logo-and-description {
                width: 35%;
                gap: 10px;
                height: 405px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .logo-box {
                    background-color: rgba(245, 245, 245, 0.4);
                    padding: 10px 10px;
                    border-radius: 5px;
                    height: 147px;
                    flex: 1;
                    display: flex;
                    justify-content: center;

                    :nth-child(1) {
                        display: flex;
                        max-height: 100%;
                        max-width: 100%;
                    }

                    // background-color: rgba(245, 245, 245, 0.4);
                    // padding: 10px 10px;
                    // border-radius: 10px;
                    // height: 140px;
                    // display: flex;
                    // align-items: center;
                    // flex: 1;
                }

                .description {
                    padding: 10px;
                    background-color: rgba(245, 245, 245, 0.4);
                    padding-left: 10px 10px;
                    font-size: 14px;
                    border-radius: 5px;
                    height: 147px;
                    flex: 1;
                    overflow-y: auto;
                    width: 93%;

                    .flip-box-desc {
                        width: 100%;
                        backface-visibility: hidden;

                        .title {
                            display: flex;
                            justify-content: center;
                            font-weight: bold;
                            padding-bottom: 5px;
                            width: 100%;
                            height: 24px;
                            font-size: 16px;
                        }

                        .content {
                            height: calc(100% - 24px);
                            overflow-y: auto;
                        }
                    }

                    .flip-box-tnc {
                        width: 100%;

                        .title {
                            display: flex;
                            justify-content: center;
                            font-weight: bold;
                            padding-bottom: 5px;
                            width: 100%;
                            height: 24px;
                            font-size: 16px;
                        }

                        .content {
                            height: calc(100% - 24px);
                            overflow-y: auto;
                        }
                    }
                }
            }

            // col1

        }

        //footer
        // > :nth-child(4) {
        //     // line-height: 35px;
        //     // margin-bottom: 20px;
        //     // margin-left: 60px;
        //     // margin-right: 16px;
        //     // padding-right: 46px;
        //     // margin-top: 5px;
        //     display: flex;
        //     height: 35px;
        //     // flex-direction: row-reverse;
        //     justify-content: center;
        //     align-items: center;
        //     gap: 20px;

        //     .joinCheckout_button {
        //         background-color: $app_theme;
        //         color: white;
        //         font-size: 12px;
        //         padding-left: 0px;
        //         padding-right: 0px;
        //         padding: 8px;
        //         border-radius: 6px;
        //         box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        //         opacity: 0.9;
        //         cursor: pointer;
        //         // margin-right: auto;
        //     }

        //     .disable {
        //         opacity: 0.4;
        //     }

        //     .share_button {
        //         background-color: rgba(0, 0, 0, 0.8);
        //         color: white;
        //         font-size: 12px;
        //         padding-left: 20px;
        //         padding-right: 20px;
        //         border-radius: 6px;
        //         box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        //         opacity: 0.9;
        //         // margin-right: 20px;
        //         // margin-left: auto;
        //         cursor: pointer;
        //         display: flex;
        //         align-items: center;

        //         >:nth-last-child(1) {
        //             margin-left: 6px;
        //         }
        //     }

        //     .disable_share {
        //         opacity: 0.7;
        //     }

        //     .close_button {
        //         background-color: white;
        //         color: black;
        //         font-size: 12px;
        //         padding-left: 20px;
        //         padding-right: 20px;
        //         border-radius: 6px;
        //         box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        //         opacity: 0.9;
        //         // margin-right: 20px;
        //         cursor: pointer;
        //         font-weight: bold;
        //     }
        // }
    }
}



@media screen and (max-width:720px) {
    .ticket-view {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // main view
        .raffle_view {
            width: 100%;
            max-height: 180px;
            position: relative;

            //background image
            >:nth-child(1) {
                max-width: 90vw;
                height: auto;
            }

            >div:nth-child(2) {
                width: 100%;
                height: 91%;
                position: absolute;
                top: 0px;
                left: 0px;
                display: flex;
                flex-direction: column;
                color: rgb(0, 0, 0, 1);
                padding: 10px 0px 0px 0px;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                gap: 5px;

                .hosted-title {
                    font-size: 12px;
                    font-weight: bold;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;

                    .short-description {
                        display: flex;
                        font-weight: 400;

                        .emp-cont {
                            width: 65px;
                        }

                        .s-des {
                            width: 175px;
                            font-size: 10px;
                            text-align: center;
                        }

                        .action-icons {
                            display: flex;
                            width: 65px;
                            gap: 5px;
                            justify-content: end;

                            .join-icon {
                                background-color: $app_theme;
                            }

                            :nth-child(n) {
                                font-size: 12px;
                            }

                            .disable {
                                opacity: 0.4;
                            }
                        }
                    }

                }

                .hosted-body {
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    overflow-y: hidden;
                    width: 100%;

                    .raffle-view-tabs {
                        height: 100%;

                        .tabs-header {
                            display: flex;
                            justify-content: space-between;
                            border-radius: 5px 5px 0px 0px;

                            :nth-child(1) {
                                border-radius: 5px 0px 0px 0px;
                            }

                            :last-child {
                                border-radius: 0px 5px 0px 0px;
                            }

                            :nth-child(n) {
                                // background-color: rgb(255, 0, 119);
                                border-width: 1px;
                                border-color: whitesmoke;
                                flex: 1;
                                padding: 5px 0px;
                                cursor: pointer;
                                font-weight: bold;
                                background-color: #ffffff73;
                                font-size: 10px;
                            }

                            .activeTab {
                                font-size: 10px;
                                background-color: #ffeff7;
                            }
                        }

                        .tabs-content {
                            height: 85px;
                            background-color: rgba(245, 245, 245, 0.4);
                            padding: 10px 10px;
                            padding-top: 0px;
                            border-radius: 0px 0px 5px 5px;
                            display: flex;
                            flex-direction: column;
                            overflow-y: auto;

                            .details-content {
                                .detail {
                                    padding-bottom: 10px;
                                    display: flex;
                                    font-size: 10px;
                                    grid-gap: 10px;
                                    gap: 10px;
                                }
                            }

                            .draws-list {
                                >table {
                                    >tbody {
                                        >tr {
                                            >td {
                                                line-height: 10px;
                                            }
                                        }
                                    }
                                }
                            }

                            .prizes-table {
                                >table {
                                    >tbody {
                                        >tr {
                                            >td {
                                                line-height: 7px;
                                            }
                                        }
                                    }
                                }
                            }

                            .participants {
                                >table {
                                    >thead {
                                        >tr {
                                            >th {
                                                line-height: 10px;
                                            }
                                        }
                                    }

                                    >tbody {
                                        >tr {
                                            >td {
                                                line-height: 10px;
                                            }

                                        }
                                    }
                                }
                            }

                            .details-content {
                                .detail {
                                    padding-bottom: 4px;
                                    display: flex;
                                    font-size: 10px;
                                    grid-gap: 10px;
                                    gap: 10px;
                                }
                            }
                        }
                    }

                    .logo-and-description {
                        width: 20%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        .logo-box {
                            background-color: rgba(245, 245, 245, 0.4);
                            padding: 5px 5px;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            :nth-child(1) {
                                display: flex;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }

                .hosted-sub-title {
                    font-size: 10px;
                    font-weight: 400;
                    // font-weight: bold;
                    opacity: 0.7;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        .addon-tabs-container {
            // margin-top: 17px;
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .drag-btn {
                border: 0;
                background-color: lightgray;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .rest-all-tabs {
                width: 100%;
                height: 185px;
                padding: 10px;
                box-sizing: border-box;
                border-radius: 5px;

                .draw-participant-winners {
                    display: flex;
                    justify-content: space-between;
                    border-radius: 5px 5px 0px 0px;
                    flex-direction: column;
                    height: 100%;

                    .addon-tabs {

                        display: flex;
                        justify-content: space-between;
                        border-radius: 5px 5px 0px 0px;

                        :nth-child(1) {
                            border-radius: 5px 0px 0px 0px;
                        }

                        :last-child {
                            border-radius: 0px 5px 0px 0px;
                        }

                        :nth-child(n) {
                            // background-color: rgb(255, 0, 119);
                            border-width: 1px;
                            border-color: whitesmoke;
                            flex: 1;
                            padding: 5px 0px;
                            cursor: pointer;
                            font-weight: bold;
                            background-color: #ffffff73;
                            font-size: 10px;
                        }

                        .activeTab2 {
                            font-size: 10px;
                            background-color: #ffeff7;
                        }
                    }

                    .addon-tabs-content {
                        display: flex;
                        flex-direction: column;
                        overflow-x: auto;
                        height: 100%;
                        border-radius: 0px 0px 5px 5px;
                        box-sizing: border-box;
                        padding: 5px;
                        background-color: rgba(245, 245, 245, 0.4);

                        :nth-child(n) {
                            // width: 100%;
                        }

                        .tandc-content {
                            font-size: 10px;
                            padding-top: 10px;
                            gap: 20px;
                        }
                    }
                }
            }
        }
    }

}