@import "../../global.scss";

.react-datepicker__input-container {
    input {
        width: 131px;
    }
}


.create_raffle_model {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    z-index: 2;

    .confirmUpdate {
        display: block;
        position: absolute;
        z-index: 99999;
    }

    .confirmNone {
        display: none;
    }

    .create_raffle_div {
        //max-width: 1100px;
        //min-width: 1100px;
        overflow-x: hidden;
        // overflow-y: hidden;
        height: 100%;
        width: 90%;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        //header
        >div:nth-child(1) {
            min-height: $base_height;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 8px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            >:nth-child(1) {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }

            >div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }

            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }

        //body
        >div:nth-child(2) {
            display: flex;
            flex: 1;
            overflow-y: auto;
            height: calc(100% - ($base_height + $base_height_m));

            >form {
                display: flex;
                flex: 1;
                padding: 10px 10px;
                flex-direction: column;
                gap: $margin_sm;
                width: 100%;
                box-sizing: border-box;

                label {
                    color: $app_text_color;
                    font-size: small;
                    // margin-bottom: 4px;
                }

                .react-datepicker__input-container input {
                    width: 131px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    padding: 8px;
                    font-size: small;
                    max-width: 135px;
                    
                }
                

                select {
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    padding: 4px;
                    font-size: small;
                    max-width: 135px;
                }

                //all first row fields
                >div:nth-child(1) {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 20px;
                    // justify-content: space-between;

                    //title
                    .crm_title {
                        display: flex;
                        flex-direction: column;
                        color: $app_text_color;
                        width: 25%;

                        .raffle-name {
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            height: 17px;
                        }

                        .error-message {
                            font-size: 12px;
                            color: red;
                            margin-left: 5px;
                            overflow: hidden;
                            transition: opacity 0.3s ease, max-height 0.3s ease;
                        }
                    }

                    .raffle-category {
                        display: flex;
                        flex-direction: column;
                        color: $app_text_color;

                        :nth-child(2) {
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                        }
                    }

                    .type {
                        display: flex;
                        flex-direction: column;
                        color: $app_text_color;

                        :nth-child(2) {
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                        }
                    }

                    .raffle-template {
                        display: flex;
                        flex-direction: column;
                        color: $app_text_color;

                        :nth-child(2) {
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                        }
                    }

                    .crm_currency {
                        display: flex;
                        flex-direction: column;

                        .ticket-price {
                            display: flex;
                            flex-direction: row;

                            .fee-switch {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                            }

                            >select {
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                                border-top-right-radius: 0px;
                                border-bottom-right-radius: 0px;
                            }

                            >input {
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                                border-top-left-radius: 0px;
                                border-bottom-left-radius: 0px;
                                border-left-width: 0px;
                                max-width: 50px;
                            }
                        }
                    }

                    .ticket-limit {
                        display: flex;
                        flex-direction: column;
                        color: $app_text_color;

                        :nth-child(2) {
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            height: 17px;
                        }
                    }
                }

                >.brief_description {
                    display: flex;
                    flex-direction: column;
                    color: $app_text_color;

                    >input {
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        font-size: small;
                        padding: 8px;
                        resize: none;
                    }
                }

                //long description
                >.detailed_description {
                    display: flex;
                    flex-direction: column;
                    color: $app_text_color;

                    >textarea {
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        font-size: small;
                        height: 40px;
                        padding: 8px;
                        resize: none;
                    }
                }

                // sub tabs
                >.sub_tabs {
                    border-radius: $base_radius;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    background-color: rgba(0, 0, 0, 0.05);
                    display: flex;
                    flex-direction: row;
                    color: $app_text_color;

                    .create-page-tabs {
                        flex: 1;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 600;
                        cursor: pointer;
                        padding: $margin_sm 0px;
                        border-radius: $base_radius;
                    }

                    .active {
                        background-color: #F6C729;
                        // color: rgb(255, 255, 255);
                        color: black;
                    }

                    // .sub-tabs-disabled {
                    //     opacity: 0.4;
                    //     cursor: default;
                    // }
                    .disabled-tabs {
                        background-color: transparent;
                        font-weight: normal;
                        border: 0px;
                    }

                    .tabs {
                        border: 0px;
                        background-color: transparent;
                        color: black;
                        font-weight: 600;
                    }
                }

                .t-and-c {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    gap: 10px;

                    .controls-and-actions {
                        border: 1px solid #ddd;
                        border-radius: 3px;
                        position: relative;
                        box-sizing: border-box;
                        padding: 20px 10px;

                        .label-TandC {
                            position: absolute;
                            top: -7px;
                            left: 10px;
                            background-color: white;
                        }

                        .cancel-checkbox {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;
                        }
                    }

                    // disclosure
                    >.disclosure {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        .disclosure-TandC {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                        }

                        // rich text editor
                        >:nth-child(1) {
                            flex: 1;
                            border-radius: 10px;
                        }

                        // styles after exapnding disclosure
                        >.expand_disclosure {
                            position: fixed;
                            top: 0px;
                            left: 0px;
                            min-width: 100vw;
                            max-height: 100vh;
                            min-height: 100vh;
                            overflow-y: scroll;
                            z-index: 999;
                        }

                        // expand icons
                        >.expand_icon {
                            position: absolute;
                            bottom: 10px;
                            right: 10px;
                            font-size: 20px;
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 5px;
                            border-radius: $base_radius;
                            cursor: pointer;
                            box-shadow: 0px 0px 3px $shadow_color;

                            &:hover {
                                opacity: 0.6;
                            }
                        }

                        >.collapse_icon {
                            position: fixed;
                            bottom: 10px;
                            right: 10px;
                            font-size: 20px;
                            background-color: rgba(0, 0, 0, 0.1);
                            padding: 5px;
                            border-radius: $base_radius;
                            cursor: pointer;
                            box-shadow: 0px 0px 3px $shadow_color;
                            z-index: 999;

                            &:hover {
                                opacity: 0.6;
                            }
                        }
                    }
                }

                .raffle-history-container {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1;
                    grid-gap: 10px;
                    gap: 10px;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    position: relative;
                    box-sizing: border-box;
                    // padding: 10px;
                    border-radius: 10px;
                    height: 300px;
                    // overflow-y: auto;


                    .table {
                        border-collapse: collapse;
                        width: 100%;
                    }

                    .table-header {
                        background-color: lightgrey;
                        position: sticky;
                        top: 0;

                        th {
                            font-size: 14px;
                            position: sticky;
                            top: 0px;
                        }

                        tr {
                            position: sticky;
                            top: 0px;
                        }
                    }

                    .history-body {
                        // height: 300px;
                        overflow-y: scroll;

                        // td{
                        //     font-size: 12px;
                        // }
                    }



                }

                .auto-actions {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    gap: 10px;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    position: relative;
                    box-sizing: border-box;
                    padding: 10px;
                    border-radius: 10px;

                    .miniParticipationCheckbox {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        label {
                            color: black;
                            font-size: small;
                            gap: 10px;
                            >input {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                                max-width: 40px;
                                margin: 0px 8px;
                            }
                        }

                        select {
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                    }

                    .extensionAndCancel {
                        margin-left: 30px;
                    }

                    .checkbox-label {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        align-items: center;
                        margin-bottom: 10px;

                       

                        .extension {
                            display: flex;
                            flex-direction: column;

                            label {
                                color: black;
                                font-size: small;
                            }

                            select {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                            }
                        }

                        .max-extensions {
                            display: flex;
                            // flex-direction: column;
                            color: black;

                            // padding-left: 10px;
                            label {
                                color: black;
                                font-size: small;
                            }

                            select {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                            }

                        }
                    }

                    .extend-note {
                        color: red;
                        font-size: small;
                        margin-top: auto;
                    }
                }

                // raffle ticket
                >.raffle_ticket {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    // overflow-y: auto;

                    >div {
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;

                        // to edit text
                        // >:nth-child(1) {
                        //     // opacity: 0.9;
                        // }

                        >:nth-child(2) {
                            width: 250px;
                            height: 160px;
                            position: relative;
                            cursor: pointer;

                            //raffle ticket image
                            > :nth-child(1) {
                                width: 256px;
                                height: 160px;
                                // transform: rotateY(90deg);
                                transition: all 0.3s ease-in-out 0.3s;
                            }

                            >:nth-child(2) {
                                width: 256px;
                                height: 160px;
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                // transform: rotateY(0deg);
                                transition: all 0.3s ease-in-out;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                >img {
                                    width: 40px;
                                    height: 30px;
                                    margin-top: 8px;
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                }

                //raffle ceremony
                >.raffle_ceremony {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    color: $app_text_color;

                    >div {
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        padding: 10px 7px;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        border-radius: 10px;

                        >div {
                            >label {
                                >input {
                                    margin-right: $margin_sm;
                                }
                            }
                        }
                    }
                }

                // //fundraising raffle proportion
                // > div:nth-child(6) {
                //     display: flex;
                //     flex-direction: column;
                //     color: $app_text_color;
                //     > div:nth-child(2) {
                //         > input {
                //             border: 1px solid rgba(0, 0, 0, 0.1);
                //             border-radius: 4px;
                //             padding: 8px;
                //             font-size: small;
                //             margin-right: 30px;
                //         }
                //     }
                // }

                //participation dates
                >.raffle_dates {
                    display: flex;
                    flex-direction: row;

                    >div {
                        flex: 1;

                        >div {
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            display: flex;
                            flex-direction: row;

                            label {
                                margin-right: 8px;
                            }

                            >div {
                                // flex: 1;
                                display: flex;
                                flex-direction: column;
                                padding-right: 20px;

                                // date time input
                                >:nth-child(2) {
                                    display: flex;

                                    >:nth-child(1) {
                                        flex: 1;
                                        letter-spacing: 3px;
                                    }
                                }
                            }
                        }
                    }
                }

                //Prizes
                >.raffle_prizes {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    height: 280px;
                    gap: 10px;

                    // winners and participants
                    .participants-section {
                        position: relative;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        box-sizing: border-box;
                        padding: 10px 1px;
                        border-radius: 10px;

                        >.title {
                            position: absolute;
                            top: -7px;
                            left: $margin_sm;
                            background-color: white;
                            padding: 0px 4px;
                        }

                        >div {
                            display: flex;
                            flex-direction: row;

                            >div {
                                display: flex;
                                flex-direction: column;
                                color: $app_text_color;
                                padding: 8px;

                                >input {
                                    width: 85px;
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: small;
                                    min-height: 15px;
                                }
                            }
                        }


                    }

                    // prizes
                    .prizes-section {
                        position: relative;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        box-sizing: border-box;
                        padding: 10px 1px;
                        border-radius: 10px;

                        >.title {
                            position: absolute;
                            top: -7px;
                            left: $margin_sm;
                            background-color: white;
                            padding: 0px 4px;
                        }

                        .num-of-winners {
                            display: flex;
                            flex-direction: column;
                            color: $app_text_color;
                            padding: 8px;

                            >input {
                                width: 85px;
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                                min-height: 15px;
                            }
                        }

                        .winners-list {
                            height: 200px;
                            overflow-y: auto;

                            >div {
                                border: 0px;
                                border-top: 1px solid rgba(0, 0, 0, 0.1);
                                padding: 8px;
                                font-size: small;
                                min-height: 15px;
                                margin-bottom: 10px;
                                display: flex;
                                align-items: center;
                                gap: $margin_sm;

                                >select {
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: small;
                                    min-height: 32px;
                                }

                                >input {
                                    width: 85px;
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: small;
                                    min-height: 15px;
                                }

                                >:nth-last-child(1) {
                                    flex: 1;
                                }
                            }
                        }

                    }
                }

                // Raffle recurrence options
                >.raffle_recurrence_options {
                    // flex: 1;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: row;
                    gap: $margin_sm;

                    // all items
                    >div {
                        position: relative;
                        // border: 1px solid rgba(0, 0, 0, 0.1);
                        
                        .regular-date-container{
                            position: relative;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            padding: 20px 10px 10px;
                            display: flex;
                            border-radius: 10px;
                            display: flex;
                            flex-direction: column;
                            gap: 20px;

                            .time-zone{
                                display: flex;
                                flex-direction: column;
                            }
                            .open{
                                display: flex;
                                flex-direction: column;
                            }
                            .close{
                                display: flex;
                                flex-direction: column;
                            }
                            .draw{
                                display: flex;
                                flex-direction: column;
                            }
                        }

                        >.title {
                            position: absolute;
                            top: -7px;
                            left: $margin_sm;
                            background-color: white;
                            padding: 0px 4px;
                        }
                    }

                    >:nth-child(1) {
                        padding: 15px 10px 10px 10px;
                        display: flex;
                        // flex: 1;
                        border-radius: 10px;
                        width: 12%;
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        >div {
                            flex: 1;
                            display: flex;
                            flex-direction: row;

                            // left
                            >:nth-child(1) {
                                display: flex;
                                flex-direction: column;
                                // border-right: 0.1px solid rgba(0, 0, 0, 0.1);
                                gap: $margin_sm;
                                padding-right: 20px;
                                width: 100%;

                                >div {
                                    >input {
                                        margin-right: $margin_sm;
                                    }
                                }
                            }

                            .series-period-container{
                                padding: 20px 10px 10px;
                                display: flex;
                                border-radius: 10px;
                                position: relative;
                                border: 1px solid rgba(0,0,0,.1);
                                margin-left: 10px;

                                .title{
                                    position: absolute;
                                    top: -7px;
                                    left: 10px;
                                    background-color: #fff;
                                    padding: 0 4px;
                                }
                                .timezone-container{
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 10px;
                                }
                                .start-date-container{
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 10px;
                                }
                                .end-date-container{
                                    display: flex;
                                    flex-direction: column;
                                    margin-bottom: 10px;
                                }
                            }

                            // right part of frequency box
                            .frequency-right {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                margin-left: $margin_sm;
                                scrollbar-width: thin;
                                // border: 1px solid rgb(0, 0, 0, 0.1);
                                // padding: 10px;
                                // border-radius: 10px;

                                .instant-raffle {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;

                                    .flex-row {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;

                                        .label {
                                            min-width: 100px;
                                        }

                                        >select {
                                            width: 119px;

                                        }
                                    }
                                }

                                .series-raffles {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                    border: 1px solid rgb(0, 0, 0, 0.1);
                                    padding: 10px;
                                    border-radius: 10px;
                                    height: 100%;

                                    .raffle-type {
                                        grid-gap: 20px;
                                        gap: 20px;
                                        display: flex;
                                        flex-direction: row;

                                       
                                    }

                                    .open-close-draw {
                                        // display: flex;
                                        // gap: 20px;
                                        // display: flex;
                                        // flex-direction: row;

                                        :nth-child(n) {
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            gap: 5px;
                                            justify-content: center;
                                        }
                                    }


                                    .series-sub-raffle {
                                        display: flex;
                                        flex-direction: row;
                                        // width: 100%;
                                        gap: 10px;

                                        .available-instances {
                                            display: flex;
                                            flex-direction: column;
                                            width: 50%;
                                            gap: 4px;

                                            .available-instances-title {
                                                text-align: left;
                                                font-weight: 700;
                                            }

                                            .occurrences {
                                                display: flex;
                                                flex-direction: column;
                                                width: 100%;
                                                height: 180px;
                                                overflow-y: auto;
                                                border: 0.1px solid rgba(0, 0, 0, 0.1);
                                                box-sizing: border-box;
                                                padding: 5px;
                                                gap: 15px;

                                                .select-all {
                                                    display: flex;
                                                    flex-direction: column;
                                                    gap: 8px;

                                                    >span {
                                                        display: flex;
                                                        gap: 10px;
                                                        font-size: 14px;

                                                        >span {
                                                            width: auto;
                                                        }
                                                    }

                                                    .all-checkbox {
                                                        display: flex;
                                                        flex-direction: column;
                                                        gap: 5px;
                                                        // height: 100%;

                                                        .each-checkbox {
                                                            display: flex;
                                                            align-items: flex-start;
                                                            justify-content: flex-start;
                                                            // margin-right: $margin_sm;
                                                            gap: 10px;
                                                            

                                                            >:nth-last-child(n) {
                                                                display: flex;
                                                                flex-wrap: wrap;

                                                                >span {
                                                                    margin-right: 10px;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .selected-instances {
                                            display: flex;
                                            flex-direction: column;
                                            width: 50%;
                                            gap: 4px;

                                            .selected-instances-title {
                                                text-align: left;
                                                font-weight: 700;
                                            }

                                            .instances-content {
                                                border: 0.1px solid rgba(0, 0, 0, 0.1);
                                                display: flex;
                                                flex-direction: column;
                                                gap: 15px;
                                                overflow-y: scroll;
                                                height: 180px;
                                                box-sizing: border-box;
                                                padding: 5px;

                                                .hourly-selected-hours {
                                                    display: flex;
                                                    flex-direction: column;
                                                    gap: 8px;

                                                    .selected-head {
                                                        font-size: 14px;
                                                    }

                                                    .selected-content {
                                                        display: flex;
                                                        flex-direction: column;
                                                        gap: 5px;
                                                        font-size: 13px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // .daily-dates {
                                    //     display: "flex";
                                    //     flex-direction: column;
                                    //     gap: "10px";
                                    //     height: 190px;
                                    //     overflow-y: scroll;
                                    // }

                                    // .daily-dates-single {
                                    //     display: grid;
                                    //     grid-template-columns: auto auto auto;
                                    //     gap: 10px;
                                    //     height: 190px;
                                    //     overflow-y: scroll;
                                    // }
                                }

                            }
                        }
                    }

                    // Raffle dates
                   .instant-date-container{
                        padding: 20px 10px 10px;
                        display: flex;
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        .instant-time-zone{
                            display: flex;
                            flex-direction: column;
                        }
                        .instant-open{
                            display: flex;
                            flex-direction: column;
                        }
                        .instant-duration{
                            display: flex;
                            flex-direction: column;
                        }
                        .instant-close{
                            display: flex;
                            flex-direction: column;
                        }
                   }
    

                    .series-period-container {
                        padding: 20px 10px 10px 10px;
                        display: flex;
                        border-radius: 10px;
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        >div {
                            display: flex;
                            flex-direction: column;
                            gap: $margin_md;

                            // timeZone
                            >:nth-child(1) {
                                >select {
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: small;
                                    max-width: 135px;
                                }
                            }

                            >div {
                                display: flex;
                                flex-direction: column;
                                .react-datepicker__tab-loop {
                                    .react-datepicker__tab-loop__start {
                                        .react-datepicker-popper{
                                            position: absolute;
                                            will-change: transform;
                                            top: 0px;
                                            left: -100px;
                                            transform: translate3d(10px, 126px, 0px);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // raffle period
                    .frequency-right {
                        padding: $margin_md $margin_sm;
                        border-radius: 10px;
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.1);

                        >div {
                            display: flex;
                            flex-direction: column;
                            gap: $margin_md;

                            .time-zone{
                                display: flex;
                                flex-direction: column;
                            }
                            .open{
                                display: flex;
                                flex-direction: column;
                            }
                            .close{
                                display: flex;
                                flex-direction: column;
                            }
                            .draw{
                                display: flex;
                                flex-direction: column;
                            }

                            .raffle-type {
                                grid-gap: 20px;
                                gap: 20px;
                                display: flex;
                                flex-direction: row;
                                width: 100%;
                            }
                            .open-close-draw{
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                // flex-direction: column;
                            }
                            .monthly-occurrences{
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                height: 180px;
                                overflow-y: auto;
                                border: 0.1px solid rgba(0,0,0,.1);
                                box-sizing: border-box;
                                padding: 5px;
                                grid-gap: 15px;
                                gap: 15px;
                            }
                            // .monthly-selected-instances {
                            //     border: 0.1px solid rgba(0,0,0,.1);
                            //     display: flex;
                            //     flex-direction: column;
                            //     grid-gap: 15px;
                            //     gap: 15px;
                            //     overflow-y: scroll;
                            //     height: 180px;
                            //     box-sizing: border-box;
                            //     padding: 5px;
                            // }
                            // >div:nth-child(2){
                            //     display: flex;
                            //     flex-direction: row;
                            //     grid-gap: 10px;
                            //     gap: 10px;
                            // }

                            // last two
                            .series-sub-raffle {
                                display: flex;
                                flex-direction: row;
                                grid-gap: 10px;
                                gap: 10px;

                                .available-instances {
                                    display: flex;
                                    flex-direction: column;
                                    gap: $margin_sm;
                                    width: 50%;
                                    .hourly-available-instances{
                                        font-weight: 700;
                                        text-align: left;
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                        font-size: 13px;
                                    }
                                    .daily-available-instances {
                                        font-weight: 700;
                                        text-align: left;
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                        font-size: 12px;
                                    }

                                    >label {
                                        margin-top: auto;
                                    }
                                    .occurrences{
                                        display: flex;
                                        flex-direction: column;
                                        width: 100%;
                                        height: 180px;
                                        overflow-y: auto;
                                        border: 0.1px solid rgba(0,0,0,.1);
                                        box-sizing: border-box;
                                        padding: 5px;
                                        grid-gap: 15px;
                                        gap: 15px;

                                        .select-all{
                                            display: flex;
                                            flex-direction: column;
                                            grid-gap: 8px;
                                            gap: 8px;

                                            >span{
                                                display: flex;
                                                grid-gap: 10px;
                                                gap: 10px;
                                                font-size: 14px;
                                            }
                                            .all-checkbox{
                                                display: flex;
                                                flex-direction: column;
                                                gap: 5px;
                                                .each-checkbox{
                                                    font-size: 13px;
                                                    display: flex;
                                                    gap: 5px;
                                                }
                                            }
                                        }
                                    }
                                }

                                .selected-instances {
                                    display: flex;
                                    flex-direction: column;
                                    width: 50%;
                                    grid-gap: 10px;
                                    gap: 10px;

                                    .selected-instances-title{
                                        text-align: left;
                                        font-weight: 700;
                                    }

                                    .instances-content{
                                        border: 0.1px solid rgba(0,0,0,.1);
                                        display: flex;
                                        flex-direction: column;
                                        grid-gap: 15px;
                                        gap: 15px;
                                        overflow-y: scroll;
                                        height: 180px;
                                        box-sizing: border-box;
                                        padding: 5px;

                                        .selected-hours{
                                            .selected-head{
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                    >input {
                                        width: 40px;
                                    }
                                }
                            }
                        }
                    }
                }

                >.include_exclude_winner_option {
                    position: relative;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: $margin_xs;
                    padding-top: $margin_xs;
                    padding-left: $margin_xs;

                    >input {
                        margin-bottom: $margin_xs;
                    }
                }

                >.raffle_landscape {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 8px;
                    gap: $margin_sm;
                    border-radius: 10px;

                    // top
                    >:nth-child(1) {
                        display: flex;
                        flex-direction: row;
                        align-content: flex-start;
                        flex-wrap: wrap;
                        gap: $margin_md;

                        >div {
                            display: flex;
                            flex-direction: column;

                            // min-width: 100px;
                            >select {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: small;
                                min-height: 32px;
                            }
                        }
                    }

                    // bottom
                    >div {
                        display: flex;
                        flex-direction: column;
                        min-width: 100px;

                        // flex: 1;
                        >textarea {
                            flex: 1;
                            border: 0px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: small;
                            min-height: 32px;
                            resize: none;
                        }
                    }

                    >:nth-last-child(1) {
                        flex: 1;
                    }
                }

                // // series raffle
                // >:nth-child(9){
                //     >div{
                //         border: 1px solid rgba(0, 0, 0, 0.1);
                //         padding: 10px 7px;
                //         display: flex;
                //         flex-direction: row;
                //         flex-wrap: wrap;
                //         gap: $margin_md;
                //         >div{
                //             display: flex;
                //             flex-direction: column;
                //             > select {
                //                 width: 200px;
                //                 min-height: 25px;
                //                 max-height: 25px;
                //                 font-size: 13px;
                //                 color: rgba(0, 0, 0, 0.8);
                //             }
                //             > input {
                //                 width: 120px;
                //                 min-height: 20px;
                //                 max-height: 20px;
                //                 font-size: 13px;
                //                 color: rgba(0, 0, 0, 0.8);
                //             }
                //         }
                //     }
                // }
            }
        }

        //footer
        >div:nth-last-child(1) {
            min-height: $base_height_m;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            .update-btn {
                margin-right: $margin_md;
                @include primary_button_sm;
            }

            .update-btn-disabled {
                margin-right: $margin_md;
                @include primary_button_sm_disabled;
            }

            // create raffle button
            .create-btn-enabled {
                margin-right: $margin_md;
                @include primary_button_sm;
                border: 0;
            }

            .create-btn-disabled {
                margin-right: $margin_md;
                @include primary_button_sm_disabled;
                border: 0;
            }

            >.saveastemplate {
                margin-right: $margin_md;
                border: 0;
                @include primary_button_sm;
            }

            >.saveastemplate-disable {
                margin-right: $margin_md;
                border: 0;
                @include primary_button_sm_disabled;
            }

            >.save_as_draft {
                margin-right: $margin_md;
                @include primary_button_sm;
                border: 0;
            }

            .left_right_arrows {
                margin-right: $margin_md;
                @include primary_button_sm;
            }

            >.cancel {
                margin-right: $margin_md;
                margin-left: $margin_md;
                @include light_button_sm;
                border: 0;
            }

            >.add_to_rafflera_button {
                margin-right: auto;
                display: flex;
                align-items: center;
                padding-left: $margin_md;

                // logo
                >img {
                    width: 22px;
                    height: 22px;
                    margin-left: 4px;
                }

                >input {
                    margin-right: $margin_xs;
                    margin-top: 3px;
                }

                >label {
                    color: $app_text_color;
                    font-size: small;
                    font-weight: 700;
                    margin-top: 3px;
                }
            }

            // > div:nth-child(3) {
            //     margin-right: auto;
            //     margin-left: 20px;
            //     padding-top: 7.5px;
            //     padding-bottom: 7.5px;
            //     padding: 10px 30px;
            //     text-align: center;
            //     font-size: 13px;
            //     background-color: rgb(218, 53, 53);
            //     color: white;
            //     border-radius: 4px;
            //     box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
            //     cursor: pointer;
            // }
        }
    }
}

// @media screen and (max-width: 720px) {
//     .create_raffle_model {
//         overflow: scroll;
//         .create_raffle_div {
//         > div:nth-last-child(1) {
//             .create-btn-enabled {
//                 margin: 0px;
//                 @include primary_button_sm;
//             }
//             .left_right_arrows  {
//                 margin-right: 8px;
//             }
//             .save_as_draft {
//                 margin-right: 8px;
//             }
//             .cancel {
//                 margin-right: 8px;
//             }
//         }

//     }
//         >div:nth-child(1) {

//             // body
//             >div:nth-child(2) {
//                 >form {
//                     min-width: 100vw;

//                     //all first row fields
//                     >div:nth-child(1) {
//                         flex-direction: column;

//                         >div {
//                             min-width: 92vw;
//                         }
//                     }

//                     // short description
//                     >div:nth-child(2) {
//                         min-width: 90vw;

//                         >input {}
//                     }

//                     //announce type and customize raffle ticket
//                     >div:nth-child(4) {
//                         flex-direction: column;
//                     }

//                     //participation
//                     >div:nth-last-child(2) {
//                         flex-direction: column;

//                         >div {
//                             >div {
//                                 flex-direction: column;

//                                 label {
//                                     margin-top: 8px;
//                                 }
//                             }
//                         }
//                     }

//                     //Prizes
//                     >div:nth-last-child(1) {
//                         max-width: 92vw;
//                     }
//                 }
//             }
//         }
//     }
// }

@media screen and (max-width: 720px) {
    .create_raffle_model {
        z-index: 2;
        .create_raffle_div {
            box-shadow: none;
            >div:nth-last-child(1) {
                min-height: 50px;
                background-color: rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                flex-wrap: wrap;
                padding-top: 10px;
                padding-bottom: 10px;

                .saveastemplate{
                    font-size: 12px;
                    margin-right: 20px;
                    border: 0;
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    margin-bottom: 10px;
                }

                .cancel {
                    margin-right: 5px;
                    margin-left: 5px;
                    background-color: white;
                    box-shadow: 0px 0px 3px rgb(177, 177, 177);
                    color: black;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                }

                .save_as_draft {
                    margin-right: 5px;
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    // margin-top: 10px;
                }
                .update-btn{
                    margin-right: 20px;
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    margin-bottom: 10px;
                }
                .saveastemplate-disable{
                    margin-right: 20px;
                    border: 0;
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: default;
                    opacity: 0.5;
                }

                .left_right_arrows {
                    margin-right: 5px;
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .create-btn-enabled {
                    margin-right: 5px;
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: x-small;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    // margin-top: 10px;
                }
            }

            >div:nth-child(2) {
                overflow-x: hidden;
                >form {
                    .series-type{
                        font-size: 12px;
                    }
                    .raffle_recurrence_options{
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        grid-gap: 10px;
                        gap: 10px;

                        >:nth-child(1){
                            padding: 15px 10px 10px 10px;
                            display: flex;
                            border-radius: 10px;
                            width: 30%;
                            >div{
                                .frequency-right{
                                    .series-raffles{
                                        .series-sub-raffle{
                                            display: flex;
                                            flex-direction: column;
                                            grid-gap: 10px;
                                            gap: 10px;

                                            .available-instances{
                                                display: flex;
                                                flex-direction: column;
                                                grid-gap: 4px;
                                                gap: 4px;
                                                width: 100%;
                                                .daily-available-instances{
                                                    font-size: 12px;
                                                }
                                            }
                                            .selected-instances{
                                                flex-direction: column;
                                                grid-gap: 4px;
                                                gap: 4px;
                                                width: 100%;
                                            }
                                        }
                                        .raffle-type{
                                            grid-gap: 20px;
                                            gap: 20px;
                                            display: flex;
                                            flex-direction: column;
                                        }
                                    }
                                }
                            }
                        }
                        >:nth-child(2){
                            padding: 20px 10px 10px 10px;
                            display: flex;
                            border-radius: 10px;
                            margin-bottom: 10px;

                        }
                    }
                    >.sub_tabs {
                        border-radius: 6px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        background-color: rgba(0, 0, 0, 0.05);
                        display: flex;
                        flex-direction: column;
                        color: black;
                        font-size: 12px;

                        .disabled-tabs {
                            background-color: transparent;
                            font-weight: normal;
                            border: 0px;
                            font-size: 12px;
                        }
                    }

                    >div:nth-child(1) {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        grid-gap: 20px;
                        gap: 20px;

                        .crm_title .raffle-name {
                            width: 200px;
                            font-size: 12px;
                        }
                    }

                    .raffle_recurrence {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }
                    .raffle_recurrence_options {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: row;
                        grid-gap: 10px;
                        grid-gap: 10px;
                        gap: 10px;
                        flex-wrap: wrap;

                        :nth-child(1) {
                            >div {
                                .frequency-right {
                                    .series-raffles {
                                        .series-sub-raffle {
                                            .selected-instances {
                                                .instances-content {
                                                    .selected-hours {
                                                        .selected-head {
                                                            font-size: 10px;
                                                        }
                                                    }
                                                }
                                            }

                                            .available-instances {
                                                .occurrences {
                                                    .select-all {
                                                        .all-checkbox {
                                                            display: flex;
                                                            flex-direction: column;
                                                            grid-gap: 5px;
                                                            gap: 5px;
                                                            .each-checkbox {
                                                                display: flex;
                                                                align-items: flex-start;
                                                                justify-content: flex-start;
                                                                // margin-right: $margin_sm;
                                                                gap: 10px;
                                                                :nth-last-child(n) {
                                                                    display: flex;
                                                                    flex-wrap: wrap;
                                                                    font-size: 10px;
                                                                }
                                                            }
                                                        }

                                                        >span {
                                                            display: flex;
                                                            flex-wrap: wrap;
                                                            gap: 4px;
                                                            font-size: 12px;
                                                            align-items: center;
                                                            >span {
                                                                width: auto;
                                                                font-size: 10px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        :nth-child(2) {
                            margin-bottom: 0px;
                            
                            .raffle-dates-title{
                                position: absolute;
                                top: 15px;
                                left: 21px;
                                background-color: white;
                                padding: 0px 4px;
                            }
                            >div {
                                // display: flex;
                                // flex-direction: column;
                                // grid-gap: 20px;
                                // gap: 20px;

                                :nth-child(1) {
                                    select {
                                        border: 0px;
                                        border: 1px solid rgba(0, 0, 0, 0.1);
                                        border-radius: 4px;
                                        padding: 8px;
                                        font-size: 10px;
                                        max-width: 135px;
                                    }
                                }
                            }
                        }
                        :nth-child(3){
                            >div{
                                :nth-last-child(-n+2){
                                    .available-instances{
                                        display: flex;
                                        flex-direction: column;
                                        grid-gap: 10px;
                                        gap: 10px;
                                        width: 102%;
                                        .occurrences{
                                            .select-all{
                                                .all-checkbox{
                                                    display: flex;
                                                    flex-direction: column;
                                                    grid-gap: 5px;
                                                    gap: 5px;
                                                    .each-checkbox{
                                                        font-size: 10px;
                                                        display: flex;
                                                        align-items: flex-start;
                                                        justify-content: flex-start;
                                                        grid-gap: 10px;
                                                        gap: 10px
                                                    }
                                                }
                                                >span{
                                                    display: flex;
                                                    flex-wrap: wrap;
                                                    gap: 4px;
                                                    font-size: 12px;
                                                    align-items: center;
                                                }
                                            }
                                        }
                                    }
                                    .selected-instances{
                                        display: flex;
                                        flex-direction: column;
                                        width: 102%;
                                        grid-gap: 10px;
                                        grid-gap: 10px;
                                        gap: 10px;

                                        .instances-content{
                                            .selected-hours{
                                                .selected-head{
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .time-zone{
                                    display: flex;
                                    flex-direction: column;
                                }
                                .raffle-type {
                                    display: flex;
                                    flex-direction: column;

                                }
                                .open-close-draw{
                                    display: flex;
                                    flex-direction: column;
                                }
                                >div:nth-child(1){
                                    // gap: 20px;
                                    // display: flex;
                                    // flex-direction: column;
                                    width: 100%;

                                    .react-datepicker {
                                        position: relative;
                                        float: left; 
                                        margin-right: 10px; 
                                        font-size: 0.8rem;
                                        background-color: #fff;
                                        color: #000;
                                        border: 1px solid #aeaeae;
                                        border-radius: 0.3rem;
                                        display: flex;
                                        flex-direction: column;
                                        left: -20px;
                                    }
                                }
                                .series-sub-raffle{
                                    display: flex;
                                    flex-direction: column;
                                    // grid-gap: 10px;
                                    // gap: 10px;
                                }
                                >div:nth-child(3){
                                    display: flex;
                                    // flex-direction: column;
                                }
                            }
                        }
                          // Raffle dates
                   .instant-date-container{
                    padding: 20px 10px 10px;
                    display: flex;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .instant-time-zone{
                        display: flex;
                        flex-direction: column;
                    }
                    .instant-open{
                        display: flex;
                        flex-direction: column;
                    }
                    .instant-duration{
                        display: flex;
                        flex-direction: column;
                    }
                    .instant-close{
                        display: flex;
                        flex-direction: column;
                    }
               }
                    }

                    .react-datepicker__input-container {
                        >input {
                            width: 131px;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            padding: 8px;
                            font-size: 10px;
                            max-width: 135px;
                        }
                    }

                    .raffle_prizes {
                        .participants-section {
                            >div {
                                >div {
                                    >input {
                                        width: 75%;
                                        border: 0px;
                                        border: 1px solid rgba(0, 0, 0, 0.1);
                                        border-radius: 4px;
                                        padding: 8px;
                                        font-size: 10px;
                                        min-height: 15px;
                                    }
                                }
                            }
                        }

                        .prizes-section {
                            .num-of-winners {
                                >input {
                                    width: 40%;
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: 10px;
                                    min-height: 15px;
                                }
                            }

                            .winners-list {
                                >div {
                                    border: 0px;
                                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                                    padding: 8px;
                                    font-size: 10px;
                                    min-height: 15px;
                                    margin-bottom: 10px;
                                    display: flex;
                                    align-items: center;
                                    grid-gap: 10px;
                                    gap: 10px;

                                    >select {
                                        border: 0px;
                                        border: 1px solid rgba(0, 0, 0, 0.1);
                                        border-radius: 4px;
                                        padding: 8px;
                                        font-size: 10px;
                                        min-height: 32px;
                                    }

                                    >input {
                                        width: 85px;
                                        border: 0px;
                                        border: 1px solid rgba(0, 0, 0, 0.1);
                                        border-radius: 4px;
                                        padding: 8px;
                                        font-size: 10px;
                                        min-height: 15px;
                                    }
                                }
                            }
                        }
                    }

                    .raffle_landscape {
                        :nth-child(1) {
                            >div {
                                >textarea {
                                    flex: 1 1;
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: 10px;
                                    min-height: 32px;
                                    resize: none;
                                }

                                >select {
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: 10px;
                                    min-height: 32px;
                                }
                            }
                        }
                    }

                    .auto-actions {
                        .miniParticipationCheckbox {
                            select {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: 11px;
                                margin-left: 5px;
                                margin-right: 5px;
                            }
                        }

                        .checkbox-label {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            grid-gap: 10px;
                            gap: 10px;
                            align-items: center;
                            margin-bottom: 10px;

                            .extension {
                                select {
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: 11px;
                                }
                            }

                            .max-extensions {
                                select {
                                    border: 0px;
                                    border: 1px solid rgba(0, 0, 0, 0.1);
                                    border-radius: 4px;
                                    padding: 8px;
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                    .raffle-history-container {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: row;
                        grid-gap: 10px;
                        gap: 10px;
                    }
                }
            }

            >div:nth-child(1) {

                >div:nth-child(2) {
                    font-weight: 500;
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            >div:nth-child(2) {
                >form {
                    label {
                        color: black;
                        font-size: 10px;
                    }

                    >div:nth-child(1) {

                        .raffle-category {

                            :nth-child(2) {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: 12px;
                            }
                        }

                        .type {

                            :nth-child(2) {
                                border: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: 12px;
                            }
                        }

                        .raffle-template {

                            :nth-child(2) {
                                order: 0px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                padding: 8px;
                                font-size: 12px;
                            }
                        }
                    }

                    .brief_description {
                        >input {
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            font-size: 12px;
                            padding: 8px;
                            resize: none;
                        }
                    }

                    .detailed_description {

                        >textarea {
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            border-radius: 4px;
                            font-size: 12px;
                            height: 40px;
                            padding: 8px;
                            resize: none;
                        }
                    }

                    select {
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        padding: 4px;
                        font-size: 10px;
                        max-width: 135px;
                    }
                }
            }
        }
    }
}