@import "../../global.scss";

.update_address{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    z-index: 9999;
    >div{
        width: 500px;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;  
        display: flex;
        flex-direction: column;
        //header
        > div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $app_text_color;
            > :nth-child(1) {
                font-weight: 500;
                font-size: large;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            > :nth-child(2) {
                margin-left: auto;
                opacity: 0.5;
                cursor: pointer;
            }
        }
        // body
        >div:nth-child(2){
            flex:1;
            display: flex;
            flex-direction: column;
            padding: 20px;
            // body
            >:nth-child(1){
                display: flex;
                justify-content: center;
                >p{
                    font-size: small;
                    margin-left: 8px;
                }
            }
            // address input
            >:nth-child(2){
                margin-top: 20px;
                font-size: small;
                padding: 10px;
                min-height: 100px;
            }
        }
        //footer
        > div:nth-last-child(1) {
            height: 60px;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 20px;
            // claim button
            > div:nth-child(1) {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: $app_theme;
                color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                margin-left: 20px;
            }
            // close button
            > div:nth-child(2) {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: rgba(0,0,0,0.2);
                color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width:720px){
    >div{
        // body
        >div:nth-child(2){
            >:nth-child(2){
                font-size: medium;
            }
        }
    }
}