@import "../../../global.scss";

.cp-transparent-bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    justify-content: center;

    .cp-container {
        display: flex;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        flex-direction: column;
        width: 500px;
        border-radius: 6px;
        height: fit-content;
        margin-top: 50px;

        .cp-header {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            height: 50px;
            width: 100%;
            background-color: rgba(23, 138, 167);
            text-transform: uppercase;
            font-weight: 500;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            .cp-x-btn {
                margin-left: auto;
                @include primary_button_sm;
                border: none;
                box-shadow: none;
                padding: 0px;
                font-size: large;
            }
        }
        .cp-body {
            background-color: rgb(255, 255, 255);
            color: rgb(25, 25, 25);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 20px;
            box-sizing: border-box;

            .label-input {
                display: flex;
                flex-direction: column;
                > label {
                    margin-left: 3px;
                    @include text_sm;
                    text-transform: uppercase;
                }
                .input-eye {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    position: relative;
                    > input {
                        padding: 7px 5px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 6px;
                        width: 250px;
                        font-size: small;
                        opacity: 0.8;
                        box-sizing: border-box;
                    }
                    .password-toggle {
                        position: absolute;
                        top: 50%;
                        right: 0px;
                        transform: translateY(-50%);
                        box-sizing: border-box;
                        background: transparent;
                        font-size: 15px;
                        width: 30px;
                        height: 100%;
                        border: none;
                    }:hover {
                        opacity: 0.8;
                    }
                }
                .error-message {
                    font-size: 12px;
                    color: red;
                    margin-left: 5px;
                    overflow: hidden;
                    transition: opacity 0.3s ease, max-height 0.3s ease;
                }
            }


        }
        .cp-footer {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            box-sizing: border-box;
            height: 50px;
            background-color: rgb(209, 209, 209);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
            gap: 15px;

            .cp-close-btn {
                @include light_button_sm;
                border: none;
            }

            .cp-confirm-btn {
                @include primary_button_sm;
                border: none;
            }
        }
    }
}


@media screen and (max-width:720px){
    .cp-transparent-bg{
        .cp-container{
            display: flex;
            box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
            flex-direction: column;
            width: 90%;
            border-radius: 6px;
            height: -moz-fit-content;
            height: fit-content;
            margin-top: 50px;

            .cp-header{
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                box-sizing: border-box;
                padding-left: 20px;
                padding-right: 20px;
                height: 50px;
                width: 100%;
                background-color: rgb(23, 138, 167);
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .cp-body{
                .label-input{
                    >label{
                        margin-left: 3px;
                        line-height: 25px;
                        color: black;
                        font-weight: 500;
                        font-size: 12px;
                        text-transform: uppercase;
                    }
                }
            }
            .cp-footer{
                .cp-close-btn{
                    background-color: white;
                    box-shadow: 0px 0px 3px rgb(177, 177, 177);
                    color: black;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    border: none;
                }
                .cp-confirm-btn{
                    background-color: rgb(23, 138, 167);
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    color: white;
                    padding: 8px 20px;
                    border-radius: 6px;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                    cursor: pointer;
                    border: none;
                }
            }
        }
    }
}