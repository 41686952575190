@import '../global.scss';

.gues-raff-view-container {
    max-width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    // header
    .header {
        background-color: $app_theme;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        .logo-png {
            width: 75px;
            height: 55px;
        }
        .app-title {
            font-family: Pacifico;
            font-size: 30px;
            margin-left: 12px;
            color: rgb(255, 255, 255);
        }
    }

    .content {
        overflow-y: auto;

        .participanty_raffle_view_model {
            position: unset;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}