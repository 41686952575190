.yearly-raffle {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .raffle-year-time{
        gap: 10px;
        display: flex;
        flex-direction: row;

        :nth-child(n) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            justify-content: center;

            .year-day-picker {
                gap: 0px;
                height: 30px;

                select {
                    height: 33px;
                }
            }
        }
    }

    .series-sub-raffle {
        display: flex;
        flex-direction: row;
        // width: 100%;
        gap: 10px;
    
        .available-instances {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 10px;
    
            >label {
                display: flex;
                align-items: center;
                >span{
                    >input{
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }
            }
            .available-instances-title {
                text-align: left;
                font-weight: 700;
            }
    
            .occurrences {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 180px;
                overflow-y: auto;
                border: 0.1px solid rgba(0, 0, 0, 0.1);
                box-sizing: border-box;
                padding: 5px;
                gap: 15px;

                .each-checkbox{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    
        .selected-instances {
            display: flex;
            flex-direction: column;
            width: 50%;
            gap: 10px;
    
            .selected-instances-title {
                text-align: left;
                font-weight: 700;
            }
    
            .instances-content {
                border: 0.1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                flex-direction: column;
                gap: 15px;
                overflow-y: scroll;
                height: 180px;
                box-sizing: border-box;
                padding: 5px;

                .each-select-instance {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }
}