@import '../global.scss';

.fcp-page{
    max-width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // header
    .header {
        background-color: $app_theme;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        .logo-png {
            width: 75px;
            height: 55px;
            opacity: 0.9;
        }
        .app-title {
            font-family: Pacifico;
            font-size: 30px;
            margin-left: 12px;
            color: rgb(255, 255, 255);
        }
    }
    .fcp-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        gap: 15px;
        .fcp-form-title {
            padding-top: 10px;
            @include text_lg;
        }
        .fcp-form {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .label-input {
                display: flex;
                flex-direction: column;
                > label {
                    margin-left: 3px;
                    @include text_sm;
                }
                .input-eye {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    position: relative;
                    > input {
                        padding: 7px 5px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 6px;
                        width: 250px;
                        font-size: small;
                        opacity: 0.8;
                        box-sizing: border-box;
                    }
                    .password-toggle {
                        position: absolute;
                        top: 50%;
                        right: 0px;
                        transform: translateY(-50%);
                        box-sizing: border-box;
                        background: transparent;
                        font-size: 15px;
                        width: 30px;
                        height: 100%;
                        border: none;
                    }:hover {
                        opacity: 0.8;
                    }
                }
                .error-message {
                    font-size: 12px;
                    color: red;
                    margin-left: 5px;
                    overflow: hidden;
                    transition: opacity 0.3s ease, max-height 0.3s ease;
                }
            }
            //continue button
            .submit-btn {
                @include primary_button;
                border: none;
                margin-top: $margin_md;
            }

            .submit-btn-disabled {
                @include primary_button;
                border: none;
                margin-top: $margin_md;
                opacity: 0.5;
            }
        }

        .fcp-success {
            width: 600px;
            height: 100px;
            background-color: rgba(0,0,0,0.04);
            box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }

    .fcp-loader {
        position: absolute;
        top:0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.1);
    }
}