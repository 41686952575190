@import "../../global.scss";

.confirmation_dialouge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    .draw-window {
        width: 85vw;
        height: 100vh;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        display: flex;
        // position: relative;
        // flex-wrap: wrap;
        flex-direction: column;
        align-items: center;

        //header
        .draw-window-header {
            height: 55px;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            .draw-window-logo {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }

            .draw-window-title {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }

            .x-btn-enabled {
                margin-left: auto;
                cursor: pointer;
                font-size: 16px;
                padding: 10px 10px;
                text-align: center;
                background-color: transparent;
                color: white;
                border-radius: 4px;
                // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                font-weight: bold;
                border-color: transparent;
            }

            .x-btn-disabled {
                margin-left: auto;
                font-size: 16px;
                padding: 10px 10px;
                text-align: center;
                background-color: transparent;
                color: rgba(255, 255, 255, 0.432);
                border-radius: 4px;
                // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                font-weight: bold;
                border-color: transparent;
            }
        }

        // body table
        .draw-window-content {
            height: calc(100% - 115px);
            width: 100%;
            // padding: 20px;
            overflow-y: auto;
            display: flex;
            // flex-direction: column;
            justify-content: center;

            // align-items: center;
            >:nth-child(1) {
                .btn {
                    width: 32px;
                    // font-size: 13px;
                    // padding-top: 7.5px;
                    // padding-bottom: 7.5px;
                    // padding: 10px 30px;
                    // text-align: center;
                    background-color: $app_theme;
                    color: white;
                    // border-radius: 4px;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    // cursor: pointer;
                    // margin-left: 20px;
                    // font-weight: bold;
                }

                .winners-table {
                    border-collapse: collapse;
                    width: 100%;

                    thead tr {

                        // for index
                        >th:nth-child(1) {
                            // border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                            // background-color: #f2f2f2;
                            width: 10px;
                        }

                        // for ticket number
                        >th:nth-child(2) {
                            // border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                            // background-color: #f2f2f2;
                            width: 160px;
                        }

                        // for winner name
                        >th:nth-child(3) {
                            // border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                            // background-color: #f2f2f2;
                            width: 150px;
                        }

                        // for Prize Type
                        >th:nth-child(4) {
                            // border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                            // background-color: #f2f2f2;
                            width: 50px;
                        }

                        // for prize
                        >th:nth-child(5) {
                            // border: 1px solid #ddd;
                            padding: 8px;
                            text-align: left;
                            // background-color: #f2f2f2;
                            width: 70px;
                        }
                    }

                    tbody tr {
                        >td:nth-child(3) {
                            font-weight: bold;
                            color: #ffba00;
                        }
                    }

                    td {
                        border: 1px solid #ddd;
                        padding: 8px;
                        text-align: left;
                    }

                    th {
                        background-color: #f2f2f2;
                    }

                    /* Specific styles for the two columns */
                    td:nth-child(1),
                    th:nth-child(1) {
                        width: 5%;
                    }

                    td:nth-child(2),
                    th:nth-child(2) {
                        width: 50%;
                    }
                }
            }
        }

        //footer
        .draw-window-footer {
            width: 100%;
            height: 60px;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 20px;
            // position: absolute;
            margin-top: auto;
            bottom: 0;
            box-sizing: border-box;

            .draw-btn {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: rgb(227, 181, 56);
                color: white;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                margin-left: 20px;
                font-weight: bold;
                border-color: transparent;
            }

            .draw-btn-disabled {
                display: none;
                // font-size: 13px;
                // padding-top: 7.5px;
                // padding-bottom: 7.5px;
                // padding: 10px 30px;
                // text-align: center;
                // background-color: $app_theme;
                // color: white;
                // border-radius: 4px;
                // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                // // cursor: pointer;
                // margin-left: 20px;
                // font-weight: bold;
                // border-color: transparent;
                // opacity: 0.5;
            }

            .close-btn {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: white;
                color: black;
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                font-weight: bold;
                border-color: transparent;
            }

            .close-btn-disabled {
                font-size: 13px;
                padding-top: 7.5px;
                padding-bottom: 7.5px;
                padding: 10px 30px;
                text-align: center;
                background-color: rgba(0, 0, 0, 0.4);
                color: rgb(255, 255, 255);
                border-radius: 4px;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                // cursor: pointer;
                opacity: 0.5;
                font-weight: bold;
                border-color: transparent;
            }
        }
    }
}

@media screen and (max-width: 720px) {
    .confirmation_dialouge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgb(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

        .draw-window {
            width: 90vw;
            height: 80vh;
            background-color: white;
            box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .draw-window-header {
                height: 40px;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: white;
                background-color: rgba(23, 138, 167, 0.9);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                .draw-window-logo {
                    height: 24px;
                    width: auto;
                    margin-right: 10px;
                }

                .draw-window-title {
                    font-weight: 500;
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }

                .x-btn-enabled {
                    margin-left: auto;
                    cursor: pointer;
                    font-size: 16px;
                    // padding: 10px 10px;
                    text-align: center;
                    background-color: transparent;
                    color: white;
                    border-radius: 4px;
                    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    font-weight: bold;
                    border-color: transparent;
                }

                .x-btn-disabled {
                    margin-left: auto;
                    font-size: 16px;
                    // padding: 10px 10px;
                    text-align: center;
                    background-color: transparent;
                    color: rgba(255, 255, 255, 0.432);
                    border-radius: 4px;
                    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    font-weight: bold;
                    border-color: transparent;
                }
            }

            .draw-window-content {
                overflow: hidden;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .drum-page {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .drum{
                        display: block;
                        position: absolute;
                        z-index: 9;
                    }
                }
            }

            .draw-window-footer {
                width: 100%;
                height: 40px;
                background-color: rgba(0, 0, 0, 0.1);
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                padding-right: 20px;
                margin-top: auto;
                bottom: 0;
                box-sizing: border-box;

                .close-btn {
                    font-size: 11px;
                    padding: 4px 19px;
                    text-align: center;
                    background-color: white;
                    color: black;
                    border-radius: 4px;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    font-weight: bold;
                    border-color: transparent;
                }

                .draw-btn {
                    font-size: 11px;
                    padding: 4px 19px;
                    text-align: center;
                    background-color: rgb(227, 181, 56);
                    color: white;
                    border-radius: 4px;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    margin-left: 20px;
                    font-weight: bold;
                    border-color: transparent;
                }
            }
        }

        .cancel-window {
            // >div:nth-child(1) {
            //     height: 70px;
            //     width: 100%;
            //     box-sizing: border-box;
            //     border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            //     padding-left: 20px;
            //     padding-right: 20px;
            //     display: flex;
            //     flex-direction: row;
            //     align-items: center;
            //     color: white;
            //     background-color: rgba(23, 138, 167, 0.9);
            //     border-top-left-radius: 6px;
            //     border-top-right-radius: 6px;
            // }
        }
    }
}