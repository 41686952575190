@import "../../global.scss";


.prizes-section {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 10px 1px;
    border-radius: 10px;

    >.title {
        position: absolute;
        top: -7px;
        left: $margin_sm;
        background-color: white;
        padding: 0px 4px;
    }

    .pr-tabs-sheet {
        width: 100%;
        margin-top: 5px;
        .checkbox-container {
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
        }
        .pr-tabs-btns-continer {
            width: 100%;
            box-sizing: border-box;
            padding: 5px;
            padding-left: 8px;
            display: flex;
            flex-direction: row;
            gap: 2px;
            align-items: end;
            overflow: auto;
            height: 60px;

            .pr-tab-btn {
                padding: 5px 12px;
                border: none;
                height: 85%;
                white-space: nowrap;
            }

            .active-tab {
                background-color: $app_theme;
                height: 100%;
                color: white;
            }
        }::-webkit-scrollbar {
            height: 5px;
        }
        .pr-tabs-content-container {
            width: 100%;

        }
    }
}