@import '../../global.scss';

.raffle_invitation {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 20px;

    >div {
        width: 600px;
        height: 345px;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        //header
        >div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            >:nth-child(1) {
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }

            >div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }

            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }

        // body
        >div:nth-child(2) {
            padding: 10px;
            display: flex;
            flex-direction: column;
            // flex: 1;
            padding-left: 20px;
            padding-right: 20px;

            >p {
                @include text_sm;
                margin-top: $margin_md;
            }

            >div:nth-child(2) {
                height: 60px;
                border: 0.2px solid rgba(0, 0, 0, 0.1);
                margin-top: 12px;
                border-radius: 6px;
                display: flex;

                >textarea {
                    flex: 1;
                    outline: none;
                    border: 0px;
                    resize: none;
                    border-radius: 6px;
                    padding: 6px;
                    font-size: 13px;
                }

                >:nth-child(2) {
                    background-color: rgba(0, 0, 0, 0.1);
                    height: 100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    color: rgba(0, 0, 0, 0.9);
                    border-top-right-radius: 6px;
                    border-bottom-right-radius: 6px;
                    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
                    cursor: pointer;

                    &:hover {
                        box-shadow: none;
                    }
                }
            }

            >:nth-child(3) {
                margin: 10px auto;
                opacity: 0.6;
            }

            >:nth-child(4) {
                height: 30px;
                border: 0.3px solid rgba(0, 0, 0, 0.2);
                border-radius: 6px;
                display: flex;

                >input {
                    flex: 1;
                    border: 0px;
                    background-color: transparent;
                    padding-left: 10px;
                    padding-right: 10px;
                    outline: none;
                }

                >:nth-child(2) {
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-top: auto;
                    margin-bottom: auto;
                    opacity: 0.8;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:720px) {
    .raffle_invitation {
        >div {
            margin: 10px;

            >div:nth-child(2){
                >p{
                    line-height: 25px;
                    color: black;
                    font-weight: 500;
                    font-size: 11px;
                    margin-top: 20px;
                }
                >div:nth-child(2){
                    >textarea{
                        flex: 1 1;
                        outline: none;
                        border: 0px;
                        resize: none;
                        border-radius: 6px;
                        padding: 6px;
                        font-size: 11px;
                    }
                }
                :nth-child(3){
                    margin: 10px auto;
                    opacity: 0.6;
                    font-size: 11px;
                }
                :nth-child(4){
                    >input{
                        flex: 1 1;
                        border: 0px;
                        background-color: transparent;
                        padding-left: 10px;
                        padding-right: 10px;
                        outline: none;
                        font-size: 11px;
                    }
                }
            }

            >div:nth-child(1){
                >div:nth-child(2){
                    font-weight: 500;
                    font-size: 15px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }
        }
    }
}