@import "../../global.scss";

.show_bene {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
        background-color: white;
        border-radius: 4px;
        box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        //header
        > div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            >:nth-child(1){
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
            > div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }
            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }//header
        > div:nth-child(1) {
            height: 40px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            display: flex;
            flex-direction: row;
            align-items: center;
            > div:nth-child(1) {
                margin-left: 10px;
                font-weight: 500;
                font-size: large;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
            > :nth-child(2) {
                margin-left: auto;
                margin-right: 10px;
                opacity: 0.4;
            }
        }
        > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            padding: 20px;
            > div:nth-child(1) {
                display: flex;
                flex-direction: row;
                > div {
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px;
                    > label {
                        opacity: 0.7;
                        font-size: 13px;
                    }
                    > text {
                        background-color: rgba(0, 0, 0, 0.1);
                        min-width: 200px;
                        padding: 6px;
                        font-size: 13px;
                        opacity: 0.9;
                        margin-top: 5px;
                    }
                }
            }
            > div:nth-child(2) {
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                margin-top: 20px;
                > label {
                    opacity: 0.7;
                    font-size: 13px;
                }
                > text {
                    background-color: rgba(0, 0, 0, 0.1);
                    min-width: 200px;
                    padding: 6px;
                    font-size: 13px;
                    opacity: 0.9;
                    margin-top: 5px;
                }
            }
        }
        //footer
        > div:nth-child(3) {
            height: 50px;
            background-color: rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            > div {
                margin-left: auto;
                margin-right: auto;
                font-size: 13px;
                width: 120px;
                text-align: center;
                padding-top: 6px;
                padding-bottom: 6px;
                color: white;
                border-radius: 4px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.3);
            }
        }
    }
}
