@import "../../global.scss";

.socialShareModel{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    z-index: 99999;
    >div{
        width: 500px;
        background-color: white;
        box-shadow: 11px 11px 0px rgba(0, 0, 0, 0.2);
        border-radius: 6px;  
        display: flex;
        flex-direction: column;
        
        //header
        > div:nth-child(1) {
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            // color: $app_text_color;
            color: white;
            background-color: $app_theme;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            >:nth-child(1){
                width: 26px;
                height: 26px;
                margin-right: 10px;
            }
            > div:nth-child(2) {
                font-weight: 500;
                font-size: 22px;
                text-transform: uppercase;
                letter-spacing: 1px;
                // font-weight: bold;
            }
            > :nth-child(3) {
                margin-left: auto;
                cursor: pointer;
            }
        }

        // body
        >div:nth-child(2){
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0px;
            // share icons container
            >div{
                width: 80%;
                background-color: rgba(0,0,0,0.05);
                padding: 20px;
                display: flex;
                flex-wrap: wrap;
                border-radius: 6px;
                >:nth-child(n){
                    outline: none;
                    margin-right: 8px;
                    margin-bottom: 4px;
                }
            }
        }
        //footer
        > div:nth-last-child(1) {
            height: 60px;
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            padding-right: 20px;
            // close button
            > div:nth-child(1) {
                @include light_button_sm;
            }
        }
    }
}

@media screen and (max-width:720px){
    .socialShareModel{
        >div{
            margin: 10px;
        }
    }
}