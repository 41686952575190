@import "../../../global.scss";


.draws-list {
    >table {
        width: 100%;
        border-collapse: collapse;

        >thead {
            border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);
            font-size: 14px;
        }

        >tbody {
            font-size: 14px;

            >tr {
                border-bottom: 0.3px solid rgba(0, 0, 0, 0.1);

                >td {
                    text-align: center;

                    .draw-btn {
                        font-size: 13px;
                        padding-top: 7.5px;
                        padding-bottom: 7.5px;
                        padding: 5px 15px;
                        text-align: center;
                        background-color: rgb(227, 181, 56);
                        color: white;
                        border-radius: 4px;
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                        margin-left: 20px;
                        font-weight: bold;
                        border-color: transparent;
                    }

                    .draw-btn-disabled {
                        font-size: 13px;
                        padding-top: 7.5px;
                        padding-bottom: 7.5px;
                        padding: 5px 15px;
                        text-align: center;
                        background-color: rgb(255, 255, 255);
                        color: grey;
                        border-radius: 4px;
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                        margin-left: 20px;
                        font-weight: bold;
                        border-color: transparent;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}